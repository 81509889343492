import { useEffect, useState } from "react";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import Lottie from "react-lottie-player";
import {
  getFeedbackRating,
  setFeedbackRating,
} from "../../publicFunctions/debriefHandler";
import { IonIcon } from "@ionic/react";
import {
  thumbsDownOutline,
  thumbsDownSharp,
  thumbsUpOutline,
  thumbsUpSharp,
} from "ionicons/icons";
import {
  dayModeDefault,
  nightModeStyle,
} from "../../publicFunctions/publicHelpers/helpers";

const LOADING = "LOADING";
const NEUTRAL = "NEUTRAL";
const UP = "UP";
const DOWN = "DOWN";

function FeedbackRating({
  battleId,
  publicJudgeUserId,
  myUserId,
  feedback,
  nightMode,
}) {
  const [thumbs, setThumbs] = useState(LOADING);
  const [comment, setComment] = useState("");
  const [systemMessage, setSystemMessage] = useState("");
  const [showCommentField, setShowCommentField] = useState(false);

  useEffect(() => {
    // First thing, we need to check if this has already been voted for
    getFeedbackRating(myUserId, publicJudgeUserId)
      .then((data) => {
        if (data) {
          if (data.thumbsUp === true) {
            setThumbs(UP);
          } else if (data && data.thumbsUp === false) {
            setThumbs(DOWN);
          }
          if (data.comment) {
            setComment(data.comment);
          }
        } else {
          setThumbs(NEUTRAL);
        }
      })
      .catch((err) => {
        console.log(err);
        setThumbs(NEUTRAL);
      });
  }, [myUserId, publicJudgeUserId]);

  const updateFeedbackRating = async (argThumbs, argComment = "") => {
    const success = await setFeedbackRating(
      myUserId,
      publicJudgeUserId,
      argThumbs,
      argComment,
      feedback,
      battleId
    );
  };

  if (thumbs === LOADING) {
    return (
      <div className="center-flex">
        <Lottie
          loop
          animationData={loadingAnimation}
          play
          style={{ width: 50, height: 50 }}
        />
      </div>
    );
  }
  return (
    <div className="flex flex--direction-column">
      <div className="feedback-rating-wrapper">
        <p className="description--small center-text bold">
          How was this feedback?
        </p>
        <p className="description--smaller center-text">
          (this information will only be shared with admins of Writing Battle)
        </p>
        <br />
        <div className="flex flex--gap-large justify-content--center align-items--center">
          <div
            onClick={() => {
              setThumbs(UP);
              updateFeedbackRating(true, comment);
              setSystemMessage("Thank you!");
              setShowCommentField(false);
            }}
            style={{ cursor: thumbs !== UP ? "pointer" : "auto" }}
          >
            <IonIcon
              className={
                "social-icon" +
                (thumbs === NEUTRAL
                  ? " feedback-thumb--up"
                  : thumbs === UP
                  ? " feedback-thumb--up--pressed"
                  : "")
              }
              icon={
                thumbs === NEUTRAL || thumbs === UP
                  ? thumbsUpSharp
                  : thumbsUpOutline
              }
            />
          </div>
          <div
            onClick={() => {
              setThumbs(DOWN);
              updateFeedbackRating(false, comment);
              setSystemMessage("Can you briefly describe what's wrong?");
              setShowCommentField(true);
            }}
            style={{ cursor: thumbs !== DOWN ? "pointer" : "auto" }}
          >
            <IonIcon
              className={
                "social-icon" +
                (thumbs === NEUTRAL
                  ? " feedback-thumb--down"
                  : thumbs === DOWN
                  ? " feedback-thumb--down--pressed"
                  : "")
              }
              icon={
                thumbs === NEUTRAL || thumbs === DOWN
                  ? thumbsDownSharp
                  : thumbsDownOutline
              }
            />
          </div>
        </div>
        <br />
        <p className="description--small center-text">{systemMessage}</p>
        <br />
        {showCommentField ? (
          <div className="register-form">
            <input
              style={nightMode ? nightModeStyle : dayModeDefault}
              id={"comment"}
              type="text"
              placeholder="How can this judge improve?"
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
            />
            <button
              className="btn btn--register min-width--larger story-submission-btn "
              onClick={() => {
                updateFeedbackRating(false, comment);
                setShowCommentField(false);
                setSystemMessage(
                  "Thank you so much. We will use this information when selecting which judges will continue with Writing Battle."
                );
              }}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FeedbackRating;
