import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { caretBackOutline } from "ionicons/icons";
import LoadingSection from "../LoadingSection";
import CardState from "../../publicFunctions/clientClasses/CardState";
import BattleCard from "../battle/BattleCard";
import {
  getNameOfBracket,
  getTrophyUrlForNumberOfMatches,
} from "../../publicFunctions/publicHelpers/finalShowdownNaming";
import {
  getCardImageUrlByCard,
  getFlagImageUrlByFlagFileName,
} from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import DebriefFSListStory from "./DebriefFSListStory";
import DebriefFSListBye from "./DebriefFSListBye";
import { BYE } from "../../publicFunctions/adminDuelHandler";
import {
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function DebriefFinalShowdown({
  debriefMeta,
  myStory,
  searchParams,
  setSearchParams,
  nightMode,
}) {
  const [renderedList, setRenderedList] = useState([]);
  const [renderedGenreList, setRenderedGenreList] = useState([]);
  const [indexOfSelectedGenre, setIndexOfSelectedGenre] = useState(-1);

  useEffect(() => {
    if (debriefMeta?.finalShowdown && indexOfSelectedGenre !== -1) {
      // render what's there
      // first we must get a map of the houses
      const tempRenderList = [];
      const houseIdToHouseInfo = new Map();
      for (let i = 1; i <= 4; i++) {
        const houseForThisGenre = debriefMeta.houses["genre" + i.toString(10)];
        for (let j = 0; j < houseForThisGenre.length; j++) {
          const house = houseForThisGenre[j];
          houseIdToHouseInfo.set(house.houseId, house);
        }
      }

      const storyIdToStoryInfo = new Map();
      for (let i = 0; i < debriefMeta.finalShowdown.storyList.length; i++) {
        const story = debriefMeta.finalShowdown.storyList[i];
        storyIdToStoryInfo.set(story.storyId, story);
      }

      // We have to match the index with the finalShowdown.genres index
      // because they may not be the same
      let genreIndex = -1;
      for (let i = 0; i < debriefMeta.finalShowdown.genres.length; i++) {
        const fsGenre = debriefMeta.finalShowdown.genres[i];
        if (fsGenre.genre === debriefMeta.genres[indexOfSelectedGenre].genre) {
          genreIndex = i;
          break;
        }
      }
      const tinyCardUrl = getCardImageUrlByCard(
        debriefMeta.genres[indexOfSelectedGenre].card,
        true
      );

      // now go through each genre and dispay the first round
      const genreContainer = [];
      const fsGenre = debriefMeta.finalShowdown.genres[genreIndex];
      const genreText = fsGenre.genre;
      genreContainer.push(
        <div
          key={"title"}
          className="debrief-genre-title--container margin--none"
        >
          <img className="debrief-genre-title-card" src={tinyCardUrl} />
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className="heading-secondary margin--none"
          >
            {genreText}
          </p>
        </div>
      );

      // Note:
      const currentRound = fsGenre.currentRound + 1;

      for (let j = currentRound; j >= 1; j--) {
        const roundToIterate = fsGenre.rounds["round" + j.toString(10)];
        if (!roundToIterate) continue;

        const tempRenderedRounds = [];
        const imageUrl = getTrophyUrlForNumberOfMatches(roundToIterate.length);
        tempRenderedRounds.push(
          <div
            key={j.toString(10) + "_roundTitle"}
            className="flex align-items--center flex--gap-smaller"
          >
            <div className="trophy-debrief">
              <img className="trophy-img" src={imageUrl} />
            </div>
            <p
              style={nightMode ? nightModeStyleWhite : {}}
              className="heading-tertiary margin--none"
            >
              {getNameOfBracket(roundToIterate.length)}
            </p>
          </div>
        );
        for (let x = 0; x < roundToIterate.length; x++) {
          const match = roundToIterate[x];
          let leftStory;
          let rightStory;
          let leftHouseInfo;
          let rightHouseInfo;
          if (match.leftStoryId !== BYE) {
            leftStory = storyIdToStoryInfo.get(match.leftStoryId);
            leftHouseInfo = houseIdToHouseInfo.get(leftStory.houseId);
          }
          if (match.rightStoryId !== BYE) {
            rightStory = storyIdToStoryInfo.get(match.rightStoryId);
            rightHouseInfo = houseIdToHouseInfo.get(rightStory.houseId);
          }

          // get info
          let leftIsPublished = false;
          let rightIsPublished = false;
          if (leftStory?.publishedUserId) {
            leftIsPublished = true;
          }
          if (rightStory?.publishedUserId) {
            rightIsPublished = true;
          }

          let leftStoryTitleClass = "description center-text";
          if (myStory?.storyId === match.leftStoryId) {
            leftStoryTitleClass += " bold";
          }
          if (leftIsPublished) {
            leftStoryTitleClass += " underline cursor--pointer";
          }
          let rightStoryTitleClass = "description center-text";
          if (myStory?.storyId === match.rightStoryId) {
            rightStoryTitleClass += " bold";
          }
          if (rightIsPublished) {
            rightStoryTitleClass += " underline cursor--pointer";
          }

          tempRenderedRounds.push(
            <div
              style={nightMode ? nightModeStyleLessDark : {}}
              key={j.toString(10) + "_" + x.toString(10) + "_match"}
              className="debrief-final-showdown-match"
            >
              {match.leftStoryId !== BYE ? (
                <DebriefFSListStory
                  match={match}
                  story={leftStory}
                  houseInfo={leftHouseInfo}
                  myStory={myStory}
                  isPublished={leftIsPublished}
                  isLeft={true}
                  debriefMeta={debriefMeta}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  otherStoryIsBye={match.rightStoryId === BYE ? true : false}
                  nightMode={nightMode}
                />
              ) : (
                <DebriefFSListBye storyTitle={rightStory.storyTitle} />
              )}

              {match.leftStoryId !== BYE && match.rightStoryId !== BYE ? (
                <p className="description center-text">vs.</p>
              ) : null}

              {match.rightStoryId !== BYE ? (
                <DebriefFSListStory
                  match={match}
                  story={rightStory}
                  houseInfo={rightHouseInfo}
                  myStory={myStory}
                  isPublished={rightIsPublished}
                  isLeft={false}
                  debriefMeta={debriefMeta}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                  otherStoryIsBye={match.leftStoryId === BYE ? true : false}
                  nightMode={nightMode}
                />
              ) : (
                <DebriefFSListBye storyTitle={leftStory.storyTitle} />
              )}
            </div>
          );
        }
        genreContainer.push(
          <div
            style={nightMode ? nightModeStyleLessDark : {}}
            key={"genre_containter_" + j.toString(10)}
            className="debrief-final-showdown-matches"
          >
            {tempRenderedRounds}
          </div>
        );
      }

      tempRenderList.push(
        <div key={"genre"} className="list-of-fs-rounds">
          {genreContainer}
        </div>
      );

      setRenderedList(tempRenderList);
    }
  }, [debriefMeta, indexOfSelectedGenre, nightMode]);

  useEffect(() => {
    if (searchParams) {
      if (searchParams.has("fsGenre")) {
        const textIndex = searchParams.get("fsGenre");
        const intIndex = parseInt(textIndex);
        if (!isNaN(intIndex) && intIndex !== indexOfSelectedGenre) {
          setIndexOfSelectedGenre(intIndex);
        }
      } else {
        setIndexOfSelectedGenre(-1);
      }
    }
  }, [searchParams]);

  const setGenre = (index) => {
    if (searchParams) {
      const tempParams = searchParams;
      tempParams.set("fsGenre", index.toString(10));
      setSearchParams(tempParams);
    }
  };

  const goBackHandler = () => {
    const params = searchParams;
    params.delete("fsGenre");
    setSearchParams(params);
  };

  // this is just to render the genre list
  useEffect(() => {
    if (debriefMeta?.genres && !renderedGenreList?.length) {
      const tempRenderedGenreList = [];
      for (let i = 0; i < debriefMeta.genres.length; i++) {
        const genreObj = debriefMeta.genres[i];
        const state = new CardState("Genre");
        state.injectCardWithDataFromServer(genreObj.card);
        state.setHardLocked(false);
        state.setLocked(false);
        tempRenderedGenreList.push(
          <div
            tabIndex={0}
            role="button"
            className="debrief-battle-card"
            key={i.toString(10) + "_" + "genreCard"}
          >
            <BattleCard
              cardState={state}
              position={0}
              requestUnlock={() => {
                setGenre(i);
              }}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={false}
              makeThisAButton={true}
            />
          </div>
        );
      }
      setRenderedGenreList(tempRenderedGenreList);
    }
  }, [debriefMeta, nightMode]);

  return (
    <div className="container">
      {indexOfSelectedGenre !== -1 ? (
        <>
          <div
            style={{
              cursor: "pointer",
              color: nightMode ? "#C2C4C2" : "#010101",
            }}
            onClick={goBackHandler}
            className="house-go-back"
          >
            <IonIcon className="social-icon" icon={caretBackOutline} />
            <p
              style={{
                display: "inline-block",
                color: nightMode ? "#C2C4C2" : "#010101",
              }}
              className="description debrief-nav-item"
            >
              Return to Genre List
            </p>
          </div>
          <div className="debrief-final-showdown-list">{renderedList}</div>
        </>
      ) : renderedGenreList?.length ? (
        <div className="debrief-final-showdown-genres">{renderedGenreList}</div>
      ) : (
        <LoadingSection nightMode={nightMode} />
      )}
    </div>
  );
}

export default DebriefFinalShowdown;
