import { db } from "../config/firebase";
import {
  collection,
  query,
  limit,
  getDoc,
  doc,
  where,
  orderBy,
  startAfter,
  setDoc,
} from "firebase/firestore";
import {
  DEFAULT_STORIES_LIST_SIZE,
  convertAllTimestampsToDatesInArray,
  getQueryAsArray,
} from "./publicHelpers/helpers";
import ContentWarning from "./clientClasses/ContentWarning";

export const commentTypeDefault = "Positive, with light criticism";
export const commentType2 = "Constructive criticism";
export const commentType3 = "Encouraging, with no criticism";
export const commentType4 = "No preference";
export const spoiler_text = "These content warnings may contain SPOILERS";
export const sA_text = "Implied or described Sexual Assault";
export const cA_text = "Implied or described Child Abuse";
export const suicide_text = "Implied or described Suicide";
export const childLoss_text = "Child Loss/Miscarriage";
export const substanceAbuse_text = "Substance Abuse";
export const racism_text = "Racism/Xenophobia";
export const phobia_text = "Homophobia/Transphobia";
export const violence_text = "Violence";
export const gore_text = "Gore";
export const sC_text = "Sexual Content";
export const eating_text = "Eating Disorder";
export const animal_text = "Implied or described Animal Abuse";
export const domesticViolence_text = "Domestic Violence";

export function cloneContentWarningObjectFromServer(serverObject) {
  const cloned = new ContentWarning(
    serverObject.spoiler ? true : false,
    serverObject.sA ? true : false,
    serverObject.cA ? true : false,
    serverObject.suicide ? true : false,
    serverObject.childLoss ? true : false,
    serverObject.substanceAbuse ? true : false,
    serverObject.racism ? true : false,
    serverObject.phobia ? true : false,
    serverObject.violence ? true : false,
    serverObject.gore ? true : false,
    serverObject.sC ? true : false,
    serverObject.eating ? true : false,
    serverObject.animal ? true : false,
    serverObject.domesticViolence ? true : false
  );
  return cloned;
}

//************************************************************************* */
export async function getDebriefMeta(battleId) {
  const debriefMetaDatabase = "debriefMeta";
  const snap = await getDoc(doc(db, debriefMetaDatabase, battleId));
  if (snap.exists) {
    const temp = snap.data();
    if (temp.nextRaids?.length) {
      convertAllTimestampsToDatesInArray(temp.nextRaids);
    }
    return temp;
  } else {
    return null;
  }
}

export async function getDebriefHouse(houseId) {
  const debriefHouseDatabase = "debriefHouses";
  const snap = await getDoc(doc(db, debriefHouseDatabase, houseId));
  if (snap.exists) {
    return snap.data();
  } else {
    return null;
  }
}
//****************************************************************************** */

export async function getDebriefStoryByStoryId(storyId) {
  const snap = await getDoc(doc(db, "debriefStories", storyId));
  if (snap.exists) {
    return snap.data();
  } else {
    return null;
  }
}

export async function getDebriefStoryByUserId(userId, battleId) {
  const q = query(
    collection(db, "debriefStories"),
    where("battleId", "==", battleId),
    where("userId", "==", userId),
    limit(1)
  );
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray[0];
  } else {
    return null;
  }
}

export async function getDebriefStoryByUserIdAndRaidId(userId, raidId) {
  const q = query(
    collection(db, "debriefStories"),
    where("battleId", "==", "tavern"),
    where("raid", "==", raidId),
    where("userId", "==", userId),
    limit(1)
  );
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray[0];
  } else {
    return null;
  }
}

export async function getBattleFeedback(storyId) {
  const snap = await getDoc(doc(db, "debriefBattleFeedback", storyId));
  if (snap.exists) {
    return snap.data();
  } else {
    return null;
  }
}

export async function getUploadedStoryByUserIdAndRaidId(userId, raidId) {
  const q = query(
    collection(db, "uploadedStories"),
    where("battleId", "==", "tavern"),
    where("raid", "==", raidId),
    where("userId", "==", userId),
    limit(1)
  );
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray[0];
  } else {
    return null;
  }
}

export async function getUploadedStory(storyId) {
  const snap = await getDoc(doc(db, "uploadedStories", storyId));
  if (snap.exists) {
    return snap.data();
  } else {
    return null;
  }
}

export async function fetchDebriefStories(
  startAfterDate,
  battleId,
  searchParams,
  specialPaginations,
  setSpecialPaginations,
  nextCommentCount,
  currentPagination
) {
  let q;
  let orderByKey = "lastCommentDate";
  let direction = "desc";
  // check if there is an additional sortBy
  let additionalSortBy = false;
  let sortByValue;
  if (searchParams?.has("sortBy") && searchParams.get("sortBy") !== "default") {
    sortByValue = searchParams.get("sortBy");
    if (sortByValue === "fewestComments") {
      additionalSortBy = true;
      orderByKey = "numberOfComments";
      direction = "asc";
    } else if (sortByValue === "mostComments") {
      additionalSortBy = true;
      orderByKey = "numberOfComments";
      direction = "desc";
    }
  }
  let filter = "";
  if (
    searchParams?.has("filterBy") &&
    searchParams.get("filterBy") !== "default"
  ) {
    filter = searchParams.get("filterBy");
  }

  const queryConstraints = [
    collection(db, "debriefStories"),
    where("battleId", "==", battleId),
  ];
  if (filter) {
    queryConstraints.push(where("genre", "==", filter));
  }
  if (additionalSortBy) {
    queryConstraints.push(orderBy(orderByKey, direction));
  }
  queryConstraints.push(orderBy("lastCommentDate", "desc"));
  if (startAfterDate) {
    if (
      additionalSortBy &&
      (nextCommentCount || nextCommentCount === 0) &&
      nextCommentCount !== "none"
    ) {
      queryConstraints.push(startAfter(nextCommentCount, startAfterDate));
    } else {
      queryConstraints.push(startAfter(startAfterDate));
    }
  }
  queryConstraints.push(limit(DEFAULT_STORIES_LIST_SIZE + 1));
  q = query(...queryConstraints);

  const tempArray = await getQueryAsArray(q);

  // do we need the new pagination?
  if (!specialPaginations && (filter || additionalSortBy)) {
    const snap = await getDoc(doc(db, "paginations", battleId));
    if (snap.exists) {
      const tempSpecialPagination = snap.data();
      specialPaginations = tempSpecialPagination;
      setSpecialPaginations(tempSpecialPagination);
    }
  }
  let pagination;
  let usesSpecialPagination = false;
  if ((specialPaginations && filter) || (additionalSortBy && sortByValue)) {
    usesSpecialPagination = true;
    if (filter && sortByValue) {
      pagination = specialPaginations[sortByValue + "_" + filter];
    } else if (filter) {
      pagination = specialPaginations[filter];
    } else if (sortByValue) {
      pagination = specialPaginations[sortByValue];
    }
  }
  let paginationHasChanged = currentPagination ? false : true;
  if (currentPagination && pagination) {
    // check if they are different!
    if (currentPagination.size === pagination.length) {
      // now check last debrief pagination objects
      const lastIndexString = pagination.length.toString(10);
      if (currentPagination.has(lastIndexString)) {
        const currentPagObj = currentPagination.get(lastIndexString);
        const pagFromArray = pagination[pagination.length - 1];
        if (
          currentPagObj.commentNumberIndex ===
            pagFromArray.commentNumberIndex &&
          currentPagObj.index === pagFromArray.index
        ) {
          paginationHasChanged = false;
        } else {
          paginationHasChanged = true;
        }
      }
    } else {
      paginationHasChanged = true;
    }
  } else if (!pagination && !startAfterDate) {
    // this is a case where we might be going back to default
    paginationHasChanged = true;
  }

  if (tempArray?.length) {
    return {
      stories: tempArray,
      pagination,
      usesSpecialPagination,
      paginationHasChanged,
    };
  } else {
    return {
      stories: [],
      pagination: null,
      usesSpecialPagination: false,
      paginationHasChanged: false,
    };
  }
}

export const DOES_NOT_EXIST = "DOES_NOT_EXIST";

function createDebriefMasterKey(battleId, key) {
  return battleId + "_DEBRIEF_" + key;
}

export async function getStoryIdByUserId(battleId, userId) {
  if (!battleId || !userId) {
    return null;
  }
  const debriefStory = await getDebriefStoryByUserId(userId, battleId);
  if (debriefStory?.storyId) {
    return debriefStory.storyId;
  } else {
    return DOES_NOT_EXIST;
  }
}

export async function getStoryIdByUserIdAndRaidId(raid, userId) {
  if (!raid || !userId) {
    return null;
  }
  // todo: something smart with local storage... however,
  // we can't guarantee that this is going to be the only raid
  // what if the user deletes the raid story and creates a new one
  const debriefStory = await getDebriefStoryByUserIdAndRaidId(userId, raid);
  let data;
  if (debriefStory) {
    data = debriefStory.storyId;
  } else {
    data = DOES_NOT_EXIST;
  }
  return data;
}

export function setDebriefLinkBetweenIds(battleId, userId, storyId) {
  if (battleId && userId && storyId) {
    // saveDataToLocalStorageWithExpiry(
    //   createDebriefMasterKey(battleId, userId),
    //   storyId,
    //   true,
    //   true
    // );
    // saveDataToLocalStorageWithExpiry(
    //   createDebriefMasterKey(battleId, storyId),
    //   userId,
    //   true,
    //   true
    // );
  }
}

export async function getFeedbackRating(myUserId, publicJudgeUserId) {
  try {
    const snap = await getDoc(
      doc(db, "feedbackRatings", publicJudgeUserId + "_" + myUserId)
    );
    if (snap.exists) {
      const temp = snap.data();
      return temp;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function setFeedbackRating(
  myUserId,
  publicJudgeUserId,
  thumbsUp,
  comment = "",
  feedback = null,
  battleId
) {
  if (thumbsUp !== true && thumbsUp !== false) {
    return false;
  }
  if (!myUserId || !publicJudgeUserId || !battleId) {
    return false;
  }
  const dataToSave = {
    userId: myUserId,
    publicJudgeUserId,
    battleId,
    feedback,
    comment,
    thumbsUp,
  };

  try {
    await setDoc(
      doc(db, "feedbackRatings", publicJudgeUserId + "_" + myUserId),
      dataToSave
    );
  } catch (err) {
    console.log(err);
    return false;
  }
  return true;
}
