import { useState } from "react";
import { IonIcon } from "@ionic/react";
import { arrowDownOutline } from "ionicons/icons";
import { deleteReply, updateReply } from "../../publicFunctions/forumHandler";
import BasicUserInfo from "../BasicUserInfo";
import DeleteAThing from "./DeleteAThing";
import ForumQuillWrapper from "./ForumQuillWrapper";
import EditAThing from "./EditAThing";
import {
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
  nightModeStyleDarkBackgroundOnly,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import TaggedUsersList from "../tag-users/TaggedUsersList";
import ReplyToMessage from "./ReplyToMessage";

function Reply({
  isModerator,
  reply,
  searchParams,
  setSearchParams,
  publicUserId,
  canEdit,
  handleReplyToMessage,
  nightMode,
}) {
  const [realContent, setRealContent] = useState(reply.content);
  const [editedContent, setEditedContent] = useState(reply.content);
  const [editing, setEditing] = useState(false);

  return (
    <div
      style={nightMode ? nightModeStyleDarkBackgroundOnly : {}}
      className={
        "reply-container" +
        (searchParams &&
        searchParams.has("searchMessage") &&
        reply.messageId === searchParams.get("searchMessage")
          ? " reply-container--color-border"
          : "")
      }
    >
      {reply?.replyToMessageId && searchParams ? (
        <ReplyToMessage
          replyToMessageId={reply.replyToMessageId}
          isWritingReply={false}
          nightMode={nightMode}
        />
      ) : null}
      {canEdit || isModerator ? (
        <DeleteAThing
          rulesType={"forum"}
          isMyThing={canEdit}
          isModerator={isModerator}
          description={"comment"}
          handleDelete={(reason) => {
            deleteReply(reply.messageId, reason ? reason : "").then(() => {
              const params = searchParams;
              params.set("repliesNum", "1");
              params.set("repliesIndex", "none");
              setSearchParams(params);
            });
          }}
        />
      ) : null}
      <div className="reply-contents">
        {publicUserId ? (
          <BasicUserInfo userId={publicUserId} nightMode={nightMode} />
        ) : (
          <div></div>
        )}
        <div key={editing} className="forum-create-topic-wrapper">
          <p className="description--smaller">
            {getSimpleTimeInUserTimeZone(
              convertTimestampToObject(reply.createdDate)
            )}
          </p>
          <br />
          <ForumQuillWrapper
            content={editing ? editedContent : realContent}
            contentHandler={(newContent) => {
              try {
                if (canEdit && editing) {
                  setEditedContent(newContent);
                }
              } catch (err) {
                console.log(err);
                console.log(editedContent);
                console.log(realContent);
                console.log(editing);
                setRealContent(null);
                setEditedContent(null);
              }
            }}
            isReadOnly={!editing}
            hideHeader={!editing}
            nightMode={nightMode}
          />
          {reply.editedDate?.seconds !== reply.createdDate?.seconds ? (
            <>
              <br />
              <p className="description--smaller">
                {"Edited: " +
                  getSimpleTimeInUserTimeZone(
                    convertTimestampToObject(reply.editedDate)
                  )}
              </p>
            </>
          ) : null}
        </div>
      </div>
      {editing ? (
        <div className="flex justify-content--center">
          <button
            onClick={() => {
              updateReply(reply.messageId, editedContent)
                .then(() => {
                  setRealContent(editedContent);
                })
                .finally(() => {
                  setEditing(false);
                });
            }}
            className="btn btn--outline min-width--even-larger margin-top-bigger"
          >
            Update Comment
          </button>
        </div>
      ) : null}
      {canEdit ? (
        <>
          <br />
          <EditAThing
            toggleEditHandler={(shouldBeEditing) => {
              setEditing(shouldBeEditing);
            }}
            argEditing={editing}
          />
        </>
      ) : null}
      {reply.taggedUsers?.length && searchParams ? (
        <TaggedUsersList
          handleUserSelectedToBeRemoved={() => {}}
          listOfTaggedUserIds={reply.taggedUsers}
          isReadOnly={true}
          nightMode={nightMode}
        />
      ) : null}
      {!canEdit && searchParams ? (
        <div className="flex justify-content--end">
          <div
            onClick={() => {
              handleReplyToMessage(reply.messageId, reply.userId);
            }}
            className="flex flex--gap-tiny align-items--center underline-on-hover cursor--pointer"
          >
            <IonIcon
              style={nightMode ? nightModeStyleWhite : {}}
              className="social-icon"
              icon={arrowDownOutline}
            />
            <p className="description--smaller">Reply</p>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Reply;
