import { useEffect, useState } from "react";
import { getPublicProfileByUserId } from "../publicFunctions/publicProfileHandler";
import DefaultProfilePicture from "../img/profile_picture.png";
import DefaultProfilePictureWhite from "../img/profile_picture_white.png";
import {
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
} from "../publicFunctions/publicHelpers/helpers";

function LastReplyUser({
  userId,
  firstText,
  secondText,
  date,
  nightMode,
  isTiny,
  truncateName,
}) {
  const [publicUserInfo, setPublicUserInfo] = useState(null);

  useEffect(() => {
    if (userId) {
      getPublicProfileByUserId(userId)
        .then((data) => {
          if (data) {
            setPublicUserInfo(data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  return (
    <div className="flex flex--direction-column align-items--end justify-content--end">
      <p className="description--smaller text-align-right">{firstText}</p>
      <p className="description--smaller text-align-right">{secondText}</p>
      <img
        className={
          isTiny ? "basic-user-info--img-smaller" : "basic-user-info--img-small"
        }
        src={
          publicUserInfo?.profilePic
            ? publicUserInfo.profilePic
            : nightMode
            ? DefaultProfilePictureWhite
            : DefaultProfilePicture
        }
      />

      <p className="description--smaller text-align-right mobile-smaller-text">
        {!publicUserInfo ? (
          "..."
        ) : publicUserInfo?.name ? (
          <p
            className={
              isTiny
                ? "description--smallest description--smaller-set-width"
                : "description mobile-wide-smaller-text"
            }
          >
            {publicUserInfo?.name
              ? isTiny && truncateName
                ? publicUserInfo.name.slice(0, 8) +
                  (publicUserInfo.name.length > 8 ? "..." : "")
                : publicUserInfo.name
              : ""}
          </p>
        ) : (
          ""
        )}
      </p>
      {date ? (
        <p className="description--smaller text-align-right mobile-smaller-text">
          {getSimpleTimeInUserTimeZone(convertTimestampToObject(date))}
        </p>
      ) : null}
    </div>
  );
}

export default LastReplyUser;
