import { db, makeSomethingPublicFunc } from "../config/firebase";
import {
  collection,
  query,
  where,
  limit,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import {
  convertAllTimestampsToDatesInObject,
  getQueryAsArray,
} from "./publicHelpers/helpers";

export async function getAttention() {
  const q = query(
    collection(db, "attention"),
    where("type", "==", "attention"),
    limit(1)
  );
  const tempArray = await getQueryAsArray(q);
  if (tempArray?.length) {
    return tempArray[0]?.message ? tempArray[0].message : "";
  } else {
    return "";
  }
}

export async function getPublicProfile(pageName) {
  let q = query(
    collection(db, "publicUsers"),
    where("pageName", "==", pageName),
    limit(1)
  );
  let tempArray;
  try {
    tempArray = await getQueryAsArray(q);
  } catch (err) {
    // maybe this profile is public?
    q = query(
      collection(db, "publicUsers"),
      where("superPublic", "==", true),
      where("pageName", "==", pageName),
      limit(1)
    );
    tempArray = await getQueryAsArray(q);
  }

  if (tempArray?.length) {
    return tempArray[0];
  } else {
    return null;
  }
}

export async function makeSomethingPublic(type, makePublic, storyId = "") {
  try {
    await makeSomethingPublicFunc({ type, makePublic, storyId });
  } catch (err) {
    return false;
  }
  return true;
}

export async function getPublicProfileByUserId(userId) {
  // we first want to check localStoage if we are caching this value
  if (!userId) {
    return null;
  }

  // okay, so it looks like we now have to check the server
  const refPublicUser = doc(db, "publicUsersTiny", userId);
  const obj = await getDoc(refPublicUser);
  if (!obj.exists()) {
    return null;
  }
  const tempData = obj.data();
  const now = new Date();
  // profilePics are urls that can be the same url by different picture
  // if the user updates them. let's store the URL with a random query
  if (tempData?.profilePic) {
    tempData.profilePic += "?" + now.getTime().toString(10);
  }
  // saveDataToLocalStorageWithExpiry(userId, tempData, true);
  return tempData;
}

export async function getMyNotifications(userId) {
  // we first want to check localStoage if we are caching this value
  if (!userId) {
    return null;
  }

  // okay, so it looks like we now have to check the server
  const refNotifications = doc(db, "notifications", userId);
  const obj = await getDoc(refNotifications);
  if (!obj.exists()) {
    return null;
  }
  const tempData = obj.data();
  convertAllTimestampsToDatesInObject(tempData);
  if (tempData.notificationsList?.length) {
    for (let i = 0; i < tempData.notificationsList.length; i++) {
      convertAllTimestampsToDatesInObject(tempData.notificationsList[i]);
    }
  }
  return tempData;
}

export async function notificationsClicked(userId) {
  if (!userId) {
    return;
  }
  const refNotifications = doc(db, "notifications", userId);
  await updateDoc(refNotifications, {
    newNotifications: false,
  });
}
