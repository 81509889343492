import { useEffect, useState } from "react";
import { DEFAULT_COMMENTS_SIZE } from "../../publicFunctions/publicHelpers/helpers";
import { fetchComments } from "../../publicFunctions/forumHandler";
import {
  DOES_NOT_EXIST,
  getStoryIdByUserId,
  getStoryIdByUserIdAndRaidId,
} from "../../publicFunctions/debriefHandler";
import Comment from "./Comment";

function Comments({
  myProfile,
  isModerator,
  uploadedStory,
  userInfo,
  forceRefreshComments,
  skipCommentId,
  nightMode,
}) {
  const [renderedComments, setRenderedComments] = useState([]);
  const [lastCreatedDate, setLastCreatedDate] = useState(null);
  const [isWorking, setIsWorking] = useState(false);
  const [noMoreComments, setNoMoreComments] = useState(false);

  async function loadComments(lastCreatedDate, storyId, clickedLoadMore) {
    setIsWorking(true);
    try {
      const comments = await fetchComments(lastCreatedDate, storyId);

      // this tells us if there are more comments or not
      if (comments.length === DEFAULT_COMMENTS_SIZE + 1) {
        setLastCreatedDate(comments[DEFAULT_COMMENTS_SIZE - 1].createdDate);
      } else if (comments.length <= DEFAULT_COMMENTS_SIZE) {
        setNoMoreComments(true);
      }

      if (comments.length) {
        const tempRendered = [];
        // even if we have 11 comments, we only want to show 10 of them
        for (let i = 0; i < comments.length && i < DEFAULT_COMMENTS_SIZE; i++) {
          const comment = comments[i];
          if (
            skipCommentId &&
            comment.commentId &&
            skipCommentId === comment.commentId
          ) {
            continue;
          }
          let tempStoryId;
          if (uploadedStory.battleId !== "tavern") {
            tempStoryId = await getStoryIdByUserId(
              uploadedStory.battleId,
              comment.userId
            );
          } else if (uploadedStory.raid) {
            tempStoryId = await getStoryIdByUserIdAndRaidId(
              uploadedStory.raid,
              comment.userId
            );
          } else {
            // TODO: make this more sophisticated, maybe going to the user's
            // featured story in tavern
          }

          let storyId = null;
          if (tempStoryId && tempStoryId !== DOES_NOT_EXIST) {
            storyId = tempStoryId;
          }

          const thisIsMyStory =
            userInfo?.userId && uploadedStory?.userId === userInfo.userId;

          tempRendered.push(
            <Comment
              key={comment.commentId}
              myProfile={myProfile}
              comment={comment}
              publicUserId={comment.userId}
              uploadedStory={uploadedStory}
              storyId={storyId}
              canEdit={
                comment?.userId &&
                userInfo?.userId &&
                comment.userId === userInfo.userId
              }
              thisIsMyStory={thisIsMyStory}
              myUserId={userInfo?.userId ? userInfo.userId : ""}
              forceRefresh={() => forceRefreshComments()}
              nightMode={nightMode}
            />
          );
        }
        if (clickedLoadMore && renderedComments?.length) {
          setRenderedComments([...renderedComments, ...tempRendered]);
        } else {
          setRenderedComments(tempRendered);
        }
      }
    } catch (err) {
      console.log(err);
      setNoMoreComments(true);
    } finally {
      setIsWorking(false);
    }
  }

  useEffect(() => {
    if (uploadedStory?.storyId && userInfo && userInfo.isLoggedIn()) {
      const clickedLoadMore = false;
      loadComments(lastCreatedDate, uploadedStory.storyId, clickedLoadMore);
    }
  }, [uploadedStory, userInfo, nightMode]);

  const onLoadMoreComments = (event) => {
    event.preventDefault();
    if (uploadedStory?.storyId) {
      const clickedLoadMore = true;
      loadComments(lastCreatedDate, uploadedStory.storyId, clickedLoadMore);
    }
  };

  return (
    <div className="container flex flex--direction-column flex--gap-normal">
      {renderedComments}
      {!noMoreComments ? (
        <div className="margin-auto">
          <button
            className={"btn btn--register min-width--larger margin-bottom-md"}
            disabled={isWorking}
            onClick={onLoadMoreComments}
          >
            Load more comments
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Comments;
