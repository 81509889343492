import { useEffect, useState } from "react";
import SiteRule from "../../publicFunctions/clientClasses/SiteRule";
import { IonIcon } from "@ionic/react";
import { caretDownOutline, caretForwardOutline } from "ionicons/icons";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";
function ForumAndDebriefRules({
  rulesType,
  readOnly = true,
  changeSelectedReason,
  nightMode,
}) {
  const [rulesArray, setRulesArray] = useState(null);
  const [renderedReadOnlyRules, setRenderedReadOnlyRules] = useState(null);
  const [renderedSelectableRules, setRenderedSelectableRules] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (rulesType) {
      const tempRulesArray = [];
      tempRulesArray.push(new SiteRule("No Harassment or Discrimination"));
      tempRulesArray.push(new SiteRule("No Politics or Religion"));
      tempRulesArray.push(
        new SiteRule("Respect Your Fellow Writers and Readers")
      );
      tempRulesArray.push(
        new SiteRule("Respect the Moderators and Admin Team")
      );
      tempRulesArray.push(new SiteRule("No Author Shaming"));
      if (rulesType === "forum") {
        tempRulesArray.push(
          new SiteRule(
            "Do not mention any specific story detail from stories you’ve judged when criticizing writing/story elements."
          )
        );
        tempRulesArray.push(new SiteRule("Do not create duplicate posts."));
        tempRulesArray.push(
          new SiteRule(
            "Do not sell anything. Self-promotion is allowed only if the content is not behind a subscription or paywall."
          )
        );
        tempRulesArray.push(
          new SiteRule("Do not make any assumptions about another member.")
        );
        tempRulesArray.push(
          new SiteRule(
            "Do not pile on. If the point has already been made and the conversation has run its course, then you don’t have to contribute."
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "Do not reveal anything about your story other than Genre if you are participating in a Peer-Judged Battle."
          )
        );
        tempRulesArray.push(
          new SiteRule(
            "We may remove threads or posts if the question has been answered."
          )
        );
        // tempRulesArray.push(
        //   new SiteRule(
        //     "We may remove posts if the context is lost because a previous post was deleted."
        //   )
        // );
        tempRulesArray.push(
          new SiteRule(
            "We may remove posts that have incorrect information about the Battles so it doesn't confuse people."
          )
        );
      } else if (rulesType === "debrief") {
        tempRulesArray.push(
          new SiteRule(
            "Please respect the type of feedback that the author requested."
          )
        );
      }
      setRulesArray(tempRulesArray);
    }
  }, [rulesType]);

  useEffect(() => {
    if (rulesArray?.length) {
      if (readOnly) {
        const tempReadOnly = [];
        for (let i = 0; i < rulesArray.length; i++) {
          tempReadOnly.push(
            <li key={"rule" + i.toString(10)} className="description--small">
              {rulesArray[i].rule}
            </li>
          );
        }
        setRenderedReadOnlyRules(tempReadOnly);
      } else {
        const tempSelectable = [];
        for (let i = 0; i < rulesArray.length; i++) {
          const rule = rulesArray[i].rule;
          tempSelectable.push(
            <button
              key={"rule" + i.toString(10)}
              className="description--small cursor--pointer text-align-right"
              onClick={(event) => {
                event.preventDefault();
                changeSelectedReason(rule);
              }}
            >
              {rule}
            </button>
          );
        }
        setRenderedSelectableRules(tempSelectable);
      }
    }
  }, [rulesArray]);

  if (renderedReadOnlyRules?.length) {
    return (
      <div className="flex flex--direction-column padding-bottom--sm">
        <div
          role="button"
          onClick={(event) => {
            event.preventDefault();
            setIsExpanded(!isExpanded);
          }}
          className={
            "expand-outline" + (nightMode ? " blue-background--darker" : "")
          }
        >
          <div className="flex flex--gap-normal">
            <IonIcon
              style={nightMode ? nightModeStyleWhite : {}}
              className="battle-icon--small"
              icon={isExpanded ? caretDownOutline : caretForwardOutline}
            ></IonIcon>
            <p role="button" className="description bold">
              Forum Rules
            </p>
          </div>
          {isExpanded ? (
            <ul className="margin-left-md padding--small">
              {renderedReadOnlyRules}
            </ul>
          ) : null}
        </div>
      </div>
    );
  } else if (renderedSelectableRules?.length) {
    return (
      <div className="flex flex--direction-column flex--gap-tiny">
        <p className="description bold">Select a reason:</p>
        {renderedSelectableRules}
      </div>
    );
  } else {
    return null;
  }
}

export default ForumAndDebriefRules;
