import { IonIcon } from "@ionic/react";
import {
  flashOutline,
  caretForwardOutline,
  caretDownOutline,
} from "ionicons/icons";
import {
  getSuperSimpleEstAndInjectTimeString,
  getSuperSimpleEstWithTime,
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import AddToCalendarWrapper from "../AddToCalendarWrapper";
import { useState } from "react";

function DetailedContainer({
  battleInfo,
  icon,
  title,
  isSubEvent,
  description,
  calEventStartDate,
  calEventEndDate,
  calEventTitle,
  subEvents,
  eventDescription,
  hideEndDate,
  eventLocation,
  nightMode,
  startTimeOverride,
  endTimeOverride,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      style={nightMode ? nightModeStyleLessDark : {}}
      className={
        !isSubEvent
          ? "detailed-card glance-card glance-card--odd"
          : "detailed-card glance-card glance-card--indent"
      }
    >
      <div className="detailed-container-grid">
        <IonIcon
          style={nightMode ? nightModeStyleWhite : {}}
          className={
            isSubEvent ? "battle-icon--small justify-self--end" : "rules-icon"
          }
          icon={icon ? icon : flashOutline}
        />
        <div className="flex flex--direction-column">
          <h3
            style={nightMode ? nightModeStyleWhite : {}}
            className={
              isSubEvent
                ? "description bold margin--none"
                : "heading-tertiary margin--none"
            }
          >
            {title}
          </h3>
          <p
            className={
              isSubEvent ? "description--smaller" : "description--small"
            }
          >
            <em>
              {startTimeOverride
                ? getSuperSimpleEstAndInjectTimeString(
                    calEventStartDate,
                    startTimeOverride
                  )
                : getSuperSimpleEstWithTime(calEventStartDate)}
            </em>
            {calEventEndDate && !hideEndDate ? (
              <>
                {" "}
                &mdash;
                <br />
                <em>
                  {endTimeOverride
                    ? getSuperSimpleEstAndInjectTimeString(
                        calEventEndDate,
                        endTimeOverride
                      )
                    : getSuperSimpleEstWithTime(calEventEndDate)}
                </em>
              </>
            ) : null}
          </p>
        </div>
        <p className={isSubEvent ? "description--small" : "description"}>
          {description}
        </p>
        {calEventStartDate &&
        calEventEndDate &&
        calEventTitle &&
        !startTimeOverride &&
        !endTimeOverride ? (
          <div className="justify-self--center detailed-rules-span">
            <AddToCalendarWrapper
              startDate={calEventStartDate}
              endDate={calEventEndDate}
              title={calEventTitle}
              description={eventDescription ? eventDescription : ""}
              locationUrl={
                !eventLocation
                  ? "https://www.writingbattle.com/battle"
                  : eventLocation
              }
            />
          </div>
        ) : null}
      </div>
      {!isSubEvent ? (
        <>
          <div
            role="button"
            onClick={(event) => {
              event.preventDefault();
              setIsExpanded(!isExpanded);
            }}
            className="flex justify-content--center align-items--center flex--gap-normal expand-outline"
          >
            <IonIcon
              style={nightMode ? nightModeStyleWhite : {}}
              className="battle-icon--small"
              icon={isExpanded ? caretDownOutline : caretForwardOutline}
            ></IonIcon>
            <p role="button" className="description bold center-text">
              {isExpanded ? "Collapse Section" : "Expand Section"}
            </p>
          </div>
          <div className="simple-divider margin--none"></div>
        </>
      ) : (
        <div className="simple-divider margin--none simple-divider-brown"></div>
      )}
      {isExpanded ? subEvents : null}
    </div>
  );
}

export default DetailedContainer;
