import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { IonIcon } from "@ionic/react";
import { cartOutline, arrowForwardOutline, cashOutline } from "ionicons/icons";
import {
  formatPriceWithCurrency,
  getBattleInfo,
  getCurrentPrice,
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import { getIconByBattleId } from "../../publicFunctions/publicHelpers/homeHelper";
import { getLatestCurrencyConversions } from "../../publicFunctions/registerHandler";
import { attemptToBuyWithCredits } from "../../config/firebase";
import { extractItemsToPurchaseFromBattles } from "../../publicFunctions/publicBattleHandler";
import CheckoutOptionsButtons from "./CheckoutOptionsButtons";

const MINIMUM_AMOUNT_OF_DOLLARS_PER_TRANSACTION = 5;

function SelectBattleController({
  selectedBattles,
  switchToCheckout,
  credit,
  switchToThankYouPage,
  refreshMyProfile,
  isLoggedIn,
  giftingMode,
  nightMode,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [renderedSelectedBattleList, setRenderedSelectedBattles] = useState([]);
  const [conversionMap, setConversionMap] = useState(null);
  const [totalUSD, setTotalUSD] = useState(0);
  const [currency, setCurrency] = useState("USD");
  const [totalInCurrency, setTotalInCurrency] = useState(0);
  const [creditToUse, setCreditToUse] = useState(0);
  const [showChoicesForPayment, setShowChoicesForPayment] = useState(false);

  useEffect(() => {
    getLatestCurrencyConversions()
      .then((result) => {
        if (result) {
          setConversionMap(result);
        } else {
          setErrorMessage(
            "Something went wrong getting the latest currency conversions. Please try refreshing the page."
          );
        }
      })
      .catch((err) => {
        setErrorMessage(
          "Something went wrong getting the latest currency conversions. Please try refreshing the page."
        );
      });
  }, []);

  useEffect(() => {
    if (conversionMap) {
      const newAmount = totalUSD * conversionMap[currency];
      setTotalInCurrency(Math.round((newAmount + Number.EPSILON) * 100) / 100);
    } else if (currency === "USD") {
      setTotalInCurrency(Math.round((totalUSD + Number.EPSILON) * 100) / 100);
    }
  }, [currency, totalUSD, conversionMap]);

  useEffect(() => {
    if (credit) {
      setCreditToUse(credit);
    }
  }, [credit]);

  useEffect(() => {
    if (!selectedBattles) return;

    const newRendered = selectedBattles.map((battle) => {
      const battleInfo = getBattleInfo(battle);
      const battleTitleSplit = battleInfo.battleTitleLong.split(" ");
      return (
        <div key={battle.battleId}>
          <div className="flex jb-flex--gap-small checkout-battle-pair">
            <IonIcon
              style={nightMode ? nightModeStyleWhite : {}}
              className="jb-battle-icon--small justify-self--center align-self--center"
              icon={getIconByBattleId(battleInfo.rawTuple.battleId)}
            />
            <p className="jb-description">
              {battleTitleSplit.slice(0, battleTitleSplit.length - 1).join(" ")}
            </p>
            <p className="jb-description">${battleInfo.currentPrice}</p>
          </div>
        </div>
      );
    });

    function isAPeerBattleSelected(selectedBattles) {
      if (selectedBattles?.length) {
        for (let i = 0; i < selectedBattles.length; i++) {
          const battle = selectedBattles[i];
          if (battle?.peerJudged) {
            return true;
          }
        }
      }
      return false;
    }

    function getAgreement(linkToRules, peerBattleSelected, isGifting) {
      const DEFAULT_AGREEMENT_1 = 'By clicking "Checkout", you agree to the ';
      const DEFAULT_AGREEMENT_2 = "Official Rules";
      const DEFAULT_AGREEMENT_3 = isGifting
        ? ", and the person(s) you are gifting to is at least 18 years of age or older."
        : ", and are at least 18 years old.";

      let defaultAgreement =
        DEFAULT_AGREEMENT_1 + DEFAULT_AGREEMENT_2 + DEFAULT_AGREEMENT_3;
      if (linkToRules) {
        defaultAgreement = (
          <>
            {DEFAULT_AGREEMENT_1}{" "}
            <a
              className={"remove-anchor-dec" + (nightMode ? " blue" : "")}
              href={linkToRules}
              target="_blank"
            >
              Official Rules
            </a>
            {peerBattleSelected ? (
              <>
                {" "}
                and the following{" "}
                <a
                  className={"remove-anchor-dec" + (nightMode ? " blue" : "")}
                  href={
                    "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/Rules%2FPeer%20Battle%20Disclaimer.pdf?alt=media"
                  }
                  target="_blank"
                >
                  Peer Battle Disclaimer
                </a>
              </>
            ) : null}
            {DEFAULT_AGREEMENT_3}
          </>
        );
      }
      return defaultAgreement;
    }

    // get new dollar amount before deductions
    let tempDollarAmount = 0;
    for (const battle of selectedBattles) {
      tempDollarAmount += getCurrentPrice(battle);
    }

    if (credit && selectedBattles.length) {
      // how much credit should I use?
      let tempCredit = 0;
      if (tempDollarAmount - credit <= 0) {
        // use just enough
        tempCredit = tempDollarAmount;
        setCreditToUse(tempCredit);
        tempDollarAmount = 0;
      } else if (
        tempDollarAmount - credit <
        MINIMUM_AMOUNT_OF_DOLLARS_PER_TRANSACTION
      ) {
        // Minimum amount
        const tooSmallAmount = tempDollarAmount - credit;
        const negateCredit =
          MINIMUM_AMOUNT_OF_DOLLARS_PER_TRANSACTION - tooSmallAmount;
        tempCredit = credit - negateCredit;
        setCreditToUse(tempCredit);
        tempDollarAmount -= tempCredit;
      } else {
        tempCredit = credit;
        setCreditToUse(tempCredit);
        tempDollarAmount -= tempCredit;
      }
      newRendered.push(
        <div key="creditKey">
          <div className="flex jb-flex--gap-small checkout-battle-pair">
            <IonIcon
              style={nightMode ? nightModeStyleWhite : {}}
              className="jb-battle-icon--small justify-self--center align-self--center"
              icon={cashOutline}
            />
            <p className="jb-description">Writing Battle Site Credit</p>
            <p className="jb-description">-${tempCredit}</p>
          </div>
        </div>
      );
    }

    setRenderedSelectedBattles(newRendered);

    // get wallet and deduct so exactly 0 or greater or requal to $5 (minimum)
    setTotalUSD(tempDollarAmount);
    if (selectedBattles.length) {
      const peerBattleSelected = isAPeerBattleSelected(selectedBattles);
      setErrorMessage(
        getAgreement(
          selectedBattles[0].rulesUrl,
          peerBattleSelected,
          giftingMode ? true : false
        )
      );
    } else {
      setErrorMessage(
        giftingMode
          ? "Please select at least one battle to gift."
          : "Please select at least one battle to join."
      );
    }
  }, [selectedBattles, credit, giftingMode, nightMode]);

  const firstTryToBuyWithCredits = async () => {
    if (!(selectedBattles.length && totalInCurrency === 0 && creditToUse)) {
      return false;
    }
    setErrorMessage("");
    setIsLoading(true);
    try {
      // user has enought site credit
      const success = await attemptToBuyWithCredits({
        itemsToPurchase: extractItemsToPurchaseFromBattles(selectedBattles),
        creditToUseFromClient: creditToUse,
        thisIsAGift: giftingMode ? true : false,
      });
      switchToThankYouPage(true, success);
      refreshMyProfile(false);
    } catch (err) {
      console.log(err.errorMessage);
      const errMess = "Hmmm something went wrong. Please try again.";
      setErrorMessage(errMess);
    } finally {
      setIsLoading(false);
      return true;
    }
  };

  const checkoutClicked = async (isStripe) => {
    setErrorMessage("");
    setIsLoading(true);
    try {
      if (selectedBattles.length && totalInCurrency === 0 && creditToUse) {
        // user has enought site credit
        const success = await attemptToBuyWithCredits({
          itemsToPurchase: extractItemsToPurchaseFromBattles(selectedBattles),
          creditToUseFromClient: creditToUse,
          thisIsAGift: giftingMode ? true : false,
        });
        switchToThankYouPage(true, success);
        refreshMyProfile(false);
      } else {
        await switchToCheckout(
          selectedBattles,
          totalInCurrency,
          creditToUse,
          currency,
          giftingMode ? true : false,
          isStripe ? true : false
        );
      }
    } catch (err) {
      console.log(err.errorMessage);
      const errMess = "Hmmm something went wrong. Please try again.";
      setErrorMessage(errMess);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="select-battle--controller">
      <div className="select-battle--controller-total">
        <div className="flex checkout-battle-pair">
          <div className="jb-battle-icon"></div>
          <p className="jb-description--larger center-text bold">
            {giftingMode ? "Battle Sleigh" : "Battle Buggy"}
          </p>
          <IonIcon
            style={nightMode ? nightModeStyleWhite : {}}
            className="jb-battle-icon justify-self--center align-self--center"
            icon={cartOutline}
          />
        </div>
        <div className="simple-divider" style={{ margin: "0" }}></div>
        <br />
        <br />
        <div className="flex flex--direction-column jb-flex--gap-smaller">
          {renderedSelectedBattleList}
        </div>
        <div className="simple-divider--dotted" style={{ margin: "0" }}></div>
        <p className="jb-description text-align-right margin-top-smaller">
          <strong>Subtotal:</strong> $
          {(Math.round(totalUSD * 100) / 100).toFixed(2)} USD
        </p>
        {conversionMap ? (
          <div
            className={
              "flex jb-flex--gap-smaller " +
              "justify-content--end flex--align-center currency-flex"
            }
          >
            <label
              className="jb-description text-align-right"
              htmlFor="selectCurrency"
            >
              Change currency:
            </label>
            <select
              style={nightMode ? nightModeStyle : {}}
              id="selectCurrency"
              className="join-battle-select center-text"
              value={currency}
              onChange={(event) => {
                setCurrency(event.target.value);
              }}
            >
              <option value="USD">USD</option>
              <option value="CAD">CAD</option>
              <option value="GBP">GBP</option>
              <option value="EUR">EUR</option>
              <option value="NZD">NZD</option>
              <option value="AUD">AUD</option>
            </select>
          </div>
        ) : null}
        {currency === "USD" ? null : (
          <div className="flex conversion-flex">
            <p className="jb-description bold">
              {formatPriceWithCurrency("USD", totalUSD)}
            </p>
            <div className="flex flex--direction-column flex--align-center">
              <IonIcon
                className="jb-battle-icon--small"
                icon={arrowForwardOutline}
              />
              <p className="jb-description--small mobile-be-gone">conversion</p>
            </div>
            <p className="jb-description bold">
              {formatPriceWithCurrency(currency, totalInCurrency)}
            </p>
          </div>
        )}
      </div>
      <div className="checkout-btn-and-error">
        {showChoicesForPayment ? (
          <CheckoutOptionsButtons
            onCancel={() => {
              setShowChoicesForPayment(false);
            }}
            onPayPalClick={() => {
              checkoutClicked(false);
            }}
            onStripeClick={() => {
              checkoutClicked(true);
            }}
            isLoading={isLoading}
          />
        ) : (
          <>
            {errorMessage ? (
              <p
                style={nightMode ? nightModeStyleWhite : {}}
                className="jb-description--smaller error-text margin-bottom-sm"
              >
                {isLoggedIn
                  ? errorMessage
                  : "Please log in or create an account to join a battle"}
              </p>
            ) : null}
            <button
              className="btn btn--register checkout-btn"
              disabled={
                isLoading ||
                !selectedBattles ||
                !selectedBattles.length ||
                !isLoggedIn
              }
              onClick={async () => {
                const boughtWithCoins = await firstTryToBuyWithCredits();
                if (!boughtWithCoins) {
                  setShowChoicesForPayment(true);
                }
              }}
            >
              {giftingMode ? "Gifting Checkout " : "Checkout "}
              {" (" + formatPriceWithCurrency(currency, totalInCurrency) + ")"}
              <div className={isLoading ? "simple-loader" : "hidden"}>
                <Lottie
                  loop
                  animationData={loadingAnimation}
                  speed={1.5}
                  play
                />
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default SelectBattleController;
