import { useEffect, useState } from "react";
import DefaultProfilePicture from "../img/profile_picture.png";
import DefaultStoryCover from "../img/default_story_cover.webp";
import loadingAnimation from "../img/animations/simpleLoading.json";
import Lottie from "react-lottie-player";
import { uploadImage } from "../config/firebase";
import { nightModeStyle } from "../publicFunctions/publicHelpers/helpers";

function ImageUploader({
  editable,
  picture,
  setPicture,
  storyId = "",
  setMessage,
  nightMode,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      setIsLoading(true);
      setMessage("Uploading...");
      const reader = new FileReader();
      reader.onloadend = async (event) => {
        const image = event.target.result;
        try {
          const result = await uploadImage({ image, storyId });
          if (result?.data?.imageUrl) {
            setPicture(result.data.imageUrl + "?" + Date.now().toString(10));
            setSelectedImage(null);
            setIsLoading(false);
            setMessage(
              "Success! Please note that new images " +
                "can take up to 24 hours to show up across the entire site."
            );
          }
        } catch (error) {
          console.error(error);
          setMessage(error.message);
          setIsLoading(false);
        }
      };
      reader.readAsDataURL(selectedImage);
    }
  }, [selectedImage]);

  const dimensionClass = storyId ? "story-cover-upload-image" : "user-image";

  return (
    <div className="profile-pic-container">
      <label htmlFor="image-input">
        <img
          className={
            !editable || isLoading
              ? dimensionClass
              : picture
              ? dimensionClass + " pic-change profile-editable"
              : dimensionClass + " pic-upload profile-editable"
          }
          src={
            !picture
              ? storyId
                ? DefaultStoryCover
                : DefaultProfilePicture
              : picture
          }
        />
        <p
          style={nightMode ? nightModeStyle : {}}
          className="edit-picture-text description center-text"
        >
          {!editable || isLoading
            ? ""
            : picture
            ? "Change Image"
            : "Upload Image"}
        </p>
        <div
          className={
            isLoading ? "simple-loader simple-loader--bottom-right" : "hidden"
          }
        >
          <Lottie
            loop
            animationData={loadingAnimation}
            speed={1.5}
            play
          ></Lottie>
        </div>
      </label>
      <input
        id="image-input"
        type="file"
        name="myImage"
        accept="image/png, image/jpeg, image/jpg"
        disabled={isLoading || !editable}
        onChange={(event) => {
          const file = event.target.files[0];
          if (!file) return;
          const lowercaseName = file.name.toLowerCase();
          if (
            !lowercaseName.endsWith(".png") &&
            !lowercaseName.endsWith(".jpg") &&
            !lowercaseName.endsWith(".jpeg")
          ) {
            setMessage("Invalid file type. The image must be a .jpg or .png");
          } else {
            setMessage("");
            setSelectedImage(event.target.files[0]);
          }
        }}
      />
    </div>
  );
}

export default ImageUploader;
