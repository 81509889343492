import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import {
  nightModeStyle,
  nightModeStyleLessDark,
} from "../../publicFunctions/publicHelpers/helpers";

function SearchBar({
  debriefMeta,
  sortByDropdownList,
  hasFilters,
  hasSortBy,
  handleSearchParamChange,
  currentSearchParams,
  handleSubmitSearchQuery,
  searchErrorMessage,
  clearSearchHandler,
  isUserSearch,
  nightMode,
}) {
  const [searchValue, setSearchValue] = useState(
    currentSearchParams?.has("search") ? currentSearchParams.get("search") : ""
  );
  const [filterValue, setFilterValue] = useState(
    currentSearchParams?.has("filterBy")
      ? currentSearchParams.get("filterBy")
      : "default"
  );
  const [sortByValue, setSortByValue] = useState(
    currentSearchParams?.has("sortBy")
      ? currentSearchParams.get("sortBy")
      : "default"
  );
  const [selectedSearchType, setSelectedSearchType] = useState(
    currentSearchParams?.has("searchType")
      ? currentSearchParams.get("searchType")
      : isUserSearch
      ? "author"
      : "title"
  );
  const [activeSearch, setActiveSearch] = useState(
    currentSearchParams?.has("search") ? true : false
  );

  const [renderedFilterDropdown, setRenderedFilterDropdown] = useState(null);
  const [renderedSortByDropdown, setRenderedSortByDropdown] = useState(null);

  useEffect(() => {
    if (debriefMeta?.genres?.length) {
      // create rendered filter dropdown
      const tempRender = [
        <option key={"filter_all"} value="default">
          {debriefMeta?.battleId === "tavern" ? "All Stories" : "All Genres"}
        </option>,
      ];
      for (let i = 0; i < debriefMeta.genres.length; i++) {
        const genre = debriefMeta.genres[i].genre;
        tempRender.push(
          <option key={i.toString(10) + "_filterOption"} value={genre}>
            {genre}
          </option>
        );
      }
      setRenderedFilterDropdown(tempRender);
    }
  }, [debriefMeta]);

  useEffect(() => {
    if (sortByDropdownList?.length) {
      // create rendered sort by dropdown
      const tempRender = [
        <option key={"sortBy_default"} value="default">
          Recent Activity
        </option>,
      ];
      for (let i = 0; i < sortByDropdownList.length; i++) {
        const sortBy = sortByDropdownList[i];
        let sortByLabel = "";
        if (sortBy === "fewestComments") {
          sortByLabel = "Fewest Comments";
        } else if (sortBy === "mostComments") {
          sortByLabel = "Most Comments";
        }
        tempRender.push(
          <option key={i.toString(10) + "_sortByOption"} value={sortBy}>
            {sortByLabel}
          </option>
        );
      }
      setRenderedSortByDropdown(tempRender);
    }
  }, [sortByDropdownList]);

  const handleFilterDrowdownSelect = (event) => {
    event.preventDefault();
    setFilterValue(event.target.value);
    handleSearchParamChange("filterBy", event.target.value);
  };

  const handleSortByDrowdownSelect = (event) => {
    event.preventDefault();
    setSortByValue(event.target.value);
    handleSearchParamChange("sortBy", event.target.value);
  };

  const handleSelectedSearchTypeChange = (event) => {
    setSelectedSearchType(event.target.value);
    // we only want to set this when we enter a search term
    // handleSearchParamChange("searchType", event.target.value);
  };

  const submitttedSearchQuery = (event) => {
    const activated = handleSubmitSearchQuery(searchValue, selectedSearchType);
    if (activated === true) {
      setActiveSearch(true);
    }
  };

  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className={
        "search-bar-container" +
        (isUserSearch ? " no-border search-bar-container--less-padding" : "")
      }
    >
      <div className="search-bar-grid">
        <div className="search-section">
          {!isUserSearch ? (
            <div className="flex flex--gap-normal">
              <label
                className="description--small margin-left-sm"
                htmlFor="titleRadio"
              >
                <input
                  style={nightMode ? nightModeStyleLessDark : {}}
                  id="titleRadio"
                  className="radio-button margin-right-smaller"
                  type="radio"
                  value="title"
                  checked={selectedSearchType === "title"}
                  onChange={handleSelectedSearchTypeChange}
                />
                Title
              </label>

              <label className="description--small" htmlFor="authorRadio">
                <input
                  id="authorRadio"
                  className="radio-button margin-right-smaller"
                  type="radio"
                  value="author"
                  checked={selectedSearchType === "author"}
                  onChange={handleSelectedSearchTypeChange}
                />
                Author
              </label>

              <label className="description--small" htmlFor="promptRadio">
                <input
                  id="promptRadio"
                  className="radio-button margin-right-smaller"
                  type="radio"
                  value="prompt"
                  checked={selectedSearchType === "prompt"}
                  onChange={handleSelectedSearchTypeChange}
                />
                Prompt
              </label>
            </div>
          ) : (
            <div className="description bold">Name Search</div>
          )}
          <div className="flex">
            <input
              style={nightMode ? nightModeStyleLessDark : {}}
              className="search-input"
              type="text"
              placeholder=""
              required
              value={searchValue}
              onChange={(event) => {
                event.preventDefault();
                setSearchValue(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  submitttedSearchQuery();
                }
              }}
            />
            <div
              role="button"
              className="search-button"
              onClick={submitttedSearchQuery}
            >
              <IonIcon className="social-icon white" icon={searchOutline} />
            </div>
          </div>
        </div>
        {hasFilters && !activeSearch ? (
          <div className="flex flex--direction-column">
            <label className="description--small" htmlFor="filterBy">
              Filter
            </label>
            <select
              style={nightMode ? nightModeStyleLessDark : {}}
              id="filterBy"
              value={filterValue}
              className="join-battle-select search-dropdown"
              onChange={handleFilterDrowdownSelect}
            >
              {renderedFilterDropdown}
            </select>
          </div>
        ) : (
          <div></div>
        )}
        {hasSortBy && !activeSearch ? (
          <div className="flex flex--direction-column">
            <label className="description--small" htmlFor="sortBy">
              Sort by
            </label>
            <select
              style={nightMode ? nightModeStyleLessDark : {}}
              id="sortBy"
              value={sortByValue}
              className="join-battle-select search-dropdown"
              onChange={handleSortByDrowdownSelect}
            >
              {renderedSortByDropdown}
            </select>
          </div>
        ) : activeSearch ? (
          <button
            className={
              "btn btn--register min-width--larger red-background--lighter"
            }
            onClick={(event) => {
              event.preventDefault();
              setActiveSearch(false);
              setSearchValue("");
              clearSearchHandler();
            }}
          >
            Clear Search
          </button>
        ) : (
          <div></div>
        )}
      </div>

      {searchErrorMessage ? (
        <p className="description bold">{searchErrorMessage}</p>
      ) : null}
    </div>
  );
}

export default SearchBar;
