import {
  dayModeStyleBlack,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function DebriefLiveProgressBar({ liveJudging, nightMode }) {
  let locked = liveJudging?.lockedCount ? liveJudging.lockedCount : 0;
  const total = liveJudging?.totalCount ? liveJudging.totalCount : 1;

  // we want to just show that it's done when it gets close
  // this is because we could literally just be waiting on one judge
  // maybe they fell asleep. It will drive people nuts.
  let isCloseEnough = false;
  if (locked && total > 10 && locked < total) {
    if (total - locked <= 5) {
      isCloseEnough = true;
    }
  }
  if (isCloseEnough) {
    locked = total;
  }

  const progressMessage =
    total <= 1
      ? ""
      : locked.toString(10) +
        " out of " +
        total.toString(10) +
        " Duels judged so far this Round";
  const postProgressMessage =
    locked === total
      ? "After the admins do an integrity check, the results for this Round will be updated."
      : "";
  const roundMessage = liveJudging?.judgingRound
    ? "Round " + liveJudging.judgingRound.toString(10) + " underway"
    : "";
  let percentage = Math.ceil((locked / total) * 100);
  if (percentage < 0) {
    percentage = 0;
  } else if (percentage > 100) {
    percentage = 100;
  }

  return (
    <div
      style={{
        position: "relative",
      }}
      className="max-width--readable-center--larger"
    >
      <p
        style={nightMode ? nightModeStyleWhite : dayModeStyleBlack}
        className="description bold center-text"
      >
        {roundMessage}
      </p>
      <div
        className="progress-bar-wrapper"
        style={{
          position: "relative",
          width: "100%",
          height: "9rem",
          backgroundColor: nightMode ? "#08161c" : "#f3f5f2",
        }}
      >
        <div
          style={{
            width: `${percentage}%`,
            height: "100%",
            backgroundColor: nightMode ? "#485138" : "#b5ca8d",
          }}
        ></div>
      </div>

      <p
        style={{
          position: "absolute",
          top: "75%",
          left: "50%",
          transform: "translate(-50%, -75%)",
          width: "100%",
          color: nightMode ? "#C2C4C2" : "#010101",
        }}
        className="description--smaller center-text padding--small"
      >
        {progressMessage}
        <br />
        <span className="description--smaller">{postProgressMessage}</span>
      </p>
    </div>
  );
}

export default DebriefLiveProgressBar;
