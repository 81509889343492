import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDebriefStories } from "../../publicFunctions/debriefHandler";
import MyDebriefStory from "./MyDebriefStory";
import {
  DEFAULT_STORIES_LIST_SIZE,
  mapStaleDebriefPagination,
  pageScrollSettings,
} from "../../publicFunctions/publicHelpers/helpers";
import BasicPageNav from "../BasicPageNav";
import { Timestamp } from "firebase/firestore";
import LoadingSection from "../LoadingSection";
import BasicPageNavControls from "../../publicFunctions/clientClasses/BasicPageNavControls";
import SearchBar from "../search/SearchBar";
import {
  getSearchDocumentsFromServer,
  search,
} from "../../publicFunctions/searchHandler";
import DebriefStory from "./DebriefStory";

function DebriefStories({
  debriefMeta,
  stalePaginationArray,
  myUploadedStory,
  refreshMyUploadedStory,
  myProfile,
  searchParams,
  setSearchParams,
  nightMode,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  // note: the user will always have an uploaded story if they
  // finished the battle... but it may not be PUBLISHED

  const topRef = useRef(null);
  const [forceRefreshNav, setForceRefreshNav] = useState(false);
  const [renderedStories, setRenderedStories] = useState([]);
  const [fullReset, setFullReset] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [noMoreStories, setNoMoreStories] = useState(false);
  const [pageNavControls, setPageNavControls] = useState(
    new BasicPageNavControls(
      "/debrief",
      "num",
      "index",
      null,
      mapStaleDebriefPagination(stalePaginationArray),
      true
    )
  );
  const [specialPaginations, setSpecialPaginations] = useState(null);
  const [searchErrorMessage, setSearchErrorMessage] = useState("");
  const [searchList, setSearchList] = useState(null);

  useEffect(() => {
    if (pageNavControls && debriefMeta?.pagination?.length > 1) {
      setPageNavControls(
        pageNavControls.mapStaleDebriefPaginationThenClone(stalePaginationArray)
      );
    }
  }, [stalePaginationArray]);

  const searchDecrement = () => {
    if (searchList) {
      const newIndex =
        searchList.indexToStart - searchList.pageSize < 0
          ? 0
          : searchList.indexToStart - searchList.pageSize;

      setSearchList({
        arrayOfDocs: JSON.parse(JSON.stringify(searchList.arrayOfDocs)),
        indexToStart: newIndex,
        size: searchList.size,
        pageSize: searchList.pageSize,
      });
      const temp = searchParams;
      temp.set("num", newIndex.toString(10));
      setSearchParams(temp);
    }
  };

  const searchIncrement = () => {
    if (searchList) {
      const newIndex =
        searchList.indexToStart + searchList.pageSize >= searchList.size
          ? searchList.indexToStart
          : searchList.indexToStart + searchList.pageSize;

      setSearchList({
        arrayOfDocs: JSON.parse(JSON.stringify(searchList.arrayOfDocs)),
        indexToStart: newIndex,
        size: searchList.size,
        pageSize: searchList.pageSize,
      });
      const temp = searchParams;
      temp.set("num", newIndex.toString(10));
      setSearchParams(temp);
    }
  };

  async function loadSearchStories(
    searchValue,
    searchType,
    battleId,
    notNewSearch,
    getSearchListOverride
  ) {
    setIsWorking(true);
    if (topRef?.current) {
      topRef.current.scrollIntoView(pageScrollSettings);
    }
    try {
      let arrayOfDocs;
      if (!notNewSearch || getSearchListOverride) {
        arrayOfDocs = await search(
          searchValue,
          searchType,
          ["debriefStory"],
          battleId
        );
      } else {
        arrayOfDocs = searchList.arrayOfDocs;
      }

      if (!arrayOfDocs.length) {
        // did not return any results. return empty list and show error
        setSearchErrorMessage("Hmmm... No stories found.");
        setRenderedStories([]);
      }

      const indexToStart =
        notNewSearch || getSearchListOverride
          ? parseInt(searchParams.get("num"))
          : 0;
      const limit = DEFAULT_STORIES_LIST_SIZE;
      setSearchList({
        arrayOfDocs,
        indexToStart,
        size: arrayOfDocs.length,
        pageSize: limit,
      });

      const arrayOfSearchObject = await getSearchDocumentsFromServer(
        arrayOfDocs,
        indexToStart,
        limit
      );

      if (arrayOfSearchObject.length) {
        const tempRendered = [];
        // even if we have 11 comments, we only want to show 10 of them
        for (
          let i = 0;
          i < arrayOfSearchObject.length && i < DEFAULT_STORIES_LIST_SIZE;
          i++
        ) {
          const story = arrayOfSearchObject[i];
          // setDebriefLinkBetweenIds(story.battleId, story.userId, story.storyId);

          tempRendered.push(
            <DebriefStory
              key={story.storyId}
              story={story}
              debriefMeta={debriefMeta}
              i={i}
              nightMode={nightMode}
            />
          );
        }
        setRenderedStories(tempRendered);
      }
    } catch (err) {
      console.log(err);
      setNoMoreStories(true);
    } finally {
      setIsWorking(false);
    }
  }

  async function loadStories(battleId) {
    setIsWorking(true);
    if (topRef?.current && searchParams?.get("index")) {
      topRef.current.scrollIntoView(pageScrollSettings);
    }
    try {
      let lastCommentDate = null;
      const currentIndex = searchParams.get("index");
      if (currentIndex && currentIndex !== "none") {
        lastCommentDate = Timestamp.fromMillis(parseInt(currentIndex));
      }
      let lastCommentCount = null;
      const currentCommentNumberIndex = searchParams.get("commentNumberIndex");
      if (
        currentCommentNumberIndex &&
        currentCommentNumberIndex !== "none" &&
        !isNaN(currentCommentNumberIndex)
      ) {
        lastCommentCount = parseInt(currentCommentNumberIndex);
      }
      const fetchedStories = await fetchDebriefStories(
        lastCommentDate,
        battleId,
        searchParams,
        specialPaginations,
        setSpecialPaginations,
        lastCommentCount,
        pageNavControls?.pagination ? pageNavControls.pagination : null
      );
      const stories = fetchedStories.stories;

      // okay, so are we using new pagination -- including if we go back to regular pagination
      // if so, then we have to make sure that our Basic Page Nav forces a refresh
      let refreshBasicPageNav = false;
      let pagination;
      if (fetchedStories.paginationHasChanged) {
        // are we using a special pagination or are we using the default one?
        if (fetchedStories.usesSpecialPagination && fetchedStories.pagination) {
          pagination = mapStaleDebriefPagination(fetchedStories.pagination);
        } else {
          pagination = mapStaleDebriefPagination(stalePaginationArray);
        }
        refreshBasicPageNav = true;
      } else {
        pagination = pageNavControls.pagination;
      }

      if (stories.length === DEFAULT_STORIES_LIST_SIZE + 1) {
        const lastCommentDate =
          stories[DEFAULT_STORIES_LIST_SIZE - 1].lastCommentDate;
        const milliseconds = Math.floor(
          lastCommentDate.seconds * 1000 +
            Math.ceil(lastCommentDate.nanoseconds / 1000000)
        );
        const nextIndex = milliseconds.toString(10);
        const numString = searchParams.get("num");
        let nextCommentCount = -1;
        if (
          stories[DEFAULT_STORIES_LIST_SIZE - 1].numberOfComments ||
          stories[DEFAULT_STORIES_LIST_SIZE - 1].numberOfComments === 0
        ) {
          nextCommentCount =
            stories[DEFAULT_STORIES_LIST_SIZE - 1].numberOfComments;
        }

        if (
          currentIndex &&
          currentIndex !== "none" &&
          currentCommentNumberIndex &&
          currentCommentNumberIndex !== "none" &&
          numString
        ) {
          pagination.set(numString, {
            index: currentIndex,
            commentNumberIndex: currentCommentNumberIndex,
          });
        }
        // since we're here, we know that there IS more pagination.
        if (pagination?.size && pagination.size === parseInt(numString)) {
          // add another page
          pagination.set((pagination.size + 1).toString(10), {
            index: nextIndex,
            commentNumberIndex: nextCommentCount,
          });
          refreshBasicPageNav = true;
        }
        if (refreshBasicPageNav) {
          setForceRefreshNav(!forceRefreshNav);
        }
        setPageNavControls(
          new BasicPageNavControls(
            pageNavControls.defaultNavigateString,
            pageNavControls.numParamName,
            pageNavControls.indexParamName,
            nextIndex,
            pagination,
            true,
            nextCommentCount
          )
        );
        setNoMoreStories(false);
      } else if (stories.length <= DEFAULT_STORIES_LIST_SIZE) {
        setNoMoreStories(true);
        if (refreshBasicPageNav) {
          setPageNavControls(
            new BasicPageNavControls(
              pageNavControls.defaultNavigateString,
              pageNavControls.numParamName,
              pageNavControls.indexParamName,
              null,
              pagination,
              true
            )
          );
          setForceRefreshNav(!forceRefreshNav);
        }
      }

      if (stories.length) {
        const tempRendered = [];
        // even if we have 11 comments, we only want to show 10 of them
        for (
          let i = 0;
          i < stories.length && i < DEFAULT_STORIES_LIST_SIZE;
          i++
        ) {
          const story = stories[i];

          tempRendered.push(
            <DebriefStory
              key={story.storyId}
              story={story}
              debriefMeta={debriefMeta}
              i={i}
              nightMode={nightMode}
            />
          );
        }
        setRenderedStories(tempRendered);
      }
    } catch (err) {
      console.log(err);
      setNoMoreStories(true);
    } finally {
      setIsWorking(false);
    }
  }

  useEffect(() => {
    if (
      debriefMeta?.battleId &&
      searchParams.has("search") &&
      searchParams.has("searchType")
    ) {
      // special case
      const getSearchListOverride =
        searchParams.has("num") &&
        searchParams.get("num") !== "new" &&
        !searchList
          ? true
          : false;
      const notNewSearch =
        searchParams.has("num") &&
        searchParams.get("num") !== "new" &&
        searchList
          ? true
          : false;
      loadSearchStories(
        searchParams.get("search"),
        searchParams.get("searchType"),
        debriefMeta.battleId,
        notNewSearch,
        getSearchListOverride
      );
    } else if (
      debriefMeta?.battleId &&
      searchParams.get("page") !== "houses" &&
      searchParams.get("page") !== "finalShowdown"
    ) {
      loadStories(debriefMeta.battleId);
    }
  }, [searchParams, fullReset, nightMode]);

  const resetListHandler = () => {
    setRenderedStories([]);
    setFullReset(!fullReset);
  };

  const handleSearchBarSearchParamChange = (key, value) => {
    const temp = searchParams;
    if (temp) {
      temp.delete("num");
      temp.delete("index");
      temp.delete("commentNumberIndex");

      if (value === "default") {
        temp.delete(key);
      } else {
        temp.set(key, value);
      }
      setSearchParams(temp);
    }
  };

  const handleSubmitSearchQuery = (value, type) => {
    const temp = searchParams;
    if (!temp || !value || !type) {
      return false;
    }
    if (value.length < 3) {
      setSearchErrorMessage("You search must be at least 3 characters!");
      return false;
    } else if (searchErrorMessage) {
      setSearchErrorMessage("");
    }
    temp.delete("index");
    temp.delete("commentNumberIndex");
    temp.set("search", value);
    temp.set("searchType", type);
    temp.set("num", "new");
    setSearchParams(temp);
    return true;
  };

  const clearSearchHandler = () => {
    const temp = searchParams;
    if (!temp) {
      return;
    }
    temp.delete("num");
    temp.delete("index");
    temp.delete("commentNumberIndex");
    temp.delete("search");
    temp.delete("searchType");
    setSearchParams(temp);
    setSearchList(null);
    setSearchErrorMessage("");
  };

  return (
    <div className="container">
      {myUploadedStory ? (
        <MyDebriefStory
          myUploadedStory={myUploadedStory}
          myProfile={myProfile}
          refreshMyUploadedStory={refreshMyUploadedStory}
          resetListHandler={resetListHandler}
          nightMode={nightMode}
          autoCollapse={true}
        />
      ) : null}

      <div ref={topRef} className="simple-divider no-top-margin"></div>
      {debriefMeta ? (
        <SearchBar
          debriefMeta={debriefMeta}
          hasFilters={debriefMeta?.genres}
          hasSortBy={true}
          sortByDropdownList={["fewestComments", "mostComments"]}
          currentSearchParams={searchParams}
          handleSearchParamChange={handleSearchBarSearchParamChange}
          handleSubmitSearchQuery={handleSubmitSearchQuery}
          searchErrorMessage={searchErrorMessage}
          clearSearchHandler={clearSearchHandler}
          nightMode={nightMode}
        />
      ) : null}

      {/* TOP NAV */}
      {renderedStories?.length || pageNavControls?.pagination ? (
        <BasicPageNav
          pageNum={
            searchParams.has("num") ? parseInt(searchParams.get("num")) : 1
          }
          noMorePages={noMoreStories}
          incrementPageHandler={() => {
            pageNavControls.incrementPageHandler(searchParams, setSearchParams);
          }}
          decrementPageHandler={() => {
            pageNavControls.decrementPageHandler(
              location,
              navigate,
              searchParams,
              setSearchParams
            );
          }}
          goToPageHandler={(page, index, commentNumberIndex) => {
            pageNavControls.goToPage(
              page,
              index,
              searchParams,
              setSearchParams,
              commentNumberIndex
            );
          }}
          isWorking={isWorking}
          pagination={pageNavControls.pagination}
          showPageDropdown={true}
          forceRefreshNav={forceRefreshNav}
          alsoIndexingComments={true}
          searchList={searchList}
          searchDecrement={searchDecrement}
          searchIncrement={searchIncrement}
          nightMode={nightMode}
        />
      ) : null}

      {/* PAYLOAD */}
      <div key={fullReset} className="debrief-stories">
        {!isWorking ? (
          renderedStories
        ) : (
          <LoadingSection nightMode={nightMode} />
        )}
      </div>

      {/* BOTTOM NAV */}
      {renderedStories?.length || pageNavControls?.pagination ? (
        <BasicPageNav
          pageNum={
            searchParams.has("num") ? parseInt(searchParams.get("num")) : 1
          }
          noMorePages={noMoreStories}
          incrementPageHandler={() => {
            pageNavControls.incrementPageHandler(searchParams, setSearchParams);
          }}
          decrementPageHandler={() => {
            pageNavControls.decrementPageHandler(
              location,
              navigate,
              searchParams,
              setSearchParams
            );
          }}
          isWorking={isWorking}
          goToPageHandler={(page, index, commentNumberIndex) => {
            pageNavControls.goToPage(
              page,
              index,
              searchParams,
              setSearchParams,
              commentNumberIndex
            );
          }}
          pagination={pageNavControls.pagination}
          showPageDropdown={true}
          forceRefreshNav={forceRefreshNav}
          alsoIndexingComments={true}
          searchList={searchList}
          searchDecrement={searchDecrement}
          searchIncrement={searchIncrement}
          nightMode={nightMode}
        />
      ) : null}

      <div className="simple-divider"></div>
    </div>
  );
}
export default DebriefStories;
