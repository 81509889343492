import { getBattleSwatchByBattleId } from "../publicFunctions/publicHelpers/homeHelper";

function BattleHeading({ battleId, battleTitle, toggleHeading }) {
  const battleSwatch = getBattleSwatchByBattleId(battleId);
  return (
    <div
      className={
        toggleHeading
          ? "battle-heading--toggle"
          : "container battle-heading--wrapper"
      }
    >
      <div
        role="button"
        style={{
          backgroundImage: `url(${battleSwatch.halfLandscape})`,
        }}
        className={
          "battle-heading" +
          (toggleHeading
            ? " margin--none battle-heading--toggle--img cursor--pointer"
            : "")
        }
        onClick={
          toggleHeading
            ? () => {
                window.open("/debrief?battleId=" + battleId, "_self");
              }
            : () => {}
        }
      >
        <div
          className={
            toggleHeading
              ? "battle-heading--text--toggle"
              : "battle-heading--text"
          }
        >
          <h2
            style={{ color: battleSwatch.colorDarkest }}
            className={
              (toggleHeading ? "description bold" : "heading-secondary") +
              " white-shadow--huge margin--none"
            }
          >
            {battleTitle}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default BattleHeading;
