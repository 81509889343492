import { useState } from "react";
import { replyToUploadedStoryComment } from "../../config/firebase";
import BasicUserInfo from "../BasicUserInfo";
import EditAThing from "../forum/EditAThing";
import { nightModeStyle } from "../../publicFunctions/publicHelpers/helpers";

const DEFAULT_ERROR =
  "Hmmmm that didn't seem to work. Please try again in a minute.";

function ReplyToComment({
  commentId,
  storyId,
  argReplyComment,
  argReplyUserId,
  thisIsMyStory,
  returnReadAvail,
  nightMode,
}) {
  // Note: the displayed reply is what appears up top, and the replyComment could be edited
  const [displayedReply, setDisplayedReply] = useState(
    argReplyComment ? argReplyComment : ""
  );
  const [replyComment, setReplyComment] = useState(
    argReplyComment ? argReplyComment : ""
  );
  const [replyUserId, setReplyUserId] = useState(
    argReplyUserId ? argReplyUserId : ""
  );
  const [editingComment, setEditingComment] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [workingMessage, setWorkingMessage] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  const onReplyToMesage = async (event) => {
    event.preventDefault();
    try {
      setWorkingMessage("One sec...");
      setIsWorking(true);
      const { data } = await replyToUploadedStoryComment({
        commentId,
        storyId,
        replyComment,
      });
      if (data.success) {
        setReplyComment(data.replyComment);
        if (displayedReply) {
          // updating
          setWorkingMessage("Successfully updated your reply!");
        } else {
          if (returnReadAvail) {
            setWorkingMessage(
              "Replied! If you haven't already done so, please take the time to return a read."
            );
          } else {
            setWorkingMessage("Successfully replied!");
          }
        }
        setDisplayedReply(data.replyComment);
        setReplyUserId(data.replyUserId);
        setChangesMade(false);
        setEditingComment(false);
      } else {
        setWorkingMessage(DEFAULT_ERROR);
      }
    } catch (err) {
      console.log(err);
      setWorkingMessage(DEFAULT_ERROR);
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <div>
      <div className="reply-to-comment-container">
        {displayedReply?.length && replyUserId && !editingComment ? (
          <div className="flex flex--direction-column">
            <p className="description--smaller underline bold">
              Reply from author:
            </p>
            <div className="flex justify-content--end align-items--center">
              <BasicUserInfo
                userId={replyUserId}
                isTiny={true}
                truncateName={true}
                nightMode={nightMode}
              />
              <div className="flex justify-content--end align-items--center flex--gap-smaller">
                <p className="description--small profile-text">
                  {displayedReply}
                </p>
                {thisIsMyStory && !editingComment ? (
                  <EditAThing
                    toggleEditHandler={(shouldBeEditing) => {
                      setEditingComment(shouldBeEditing);
                    }}
                    argEditing={editingComment}
                  />
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {thisIsMyStory && (editingComment || !displayedReply) ? (
          <div>
            <label
              className="description bold"
              htmlFor={"replyToComment" + commentId}
            >
              Reply to this comment
            </label>
            <textarea
              style={nightMode ? nightModeStyle : {}}
              id={"replyToComment" + commentId}
              className="feedback-text-area text-area--resize-vertical"
              value={replyComment}
              disabled={isWorking}
              onChange={(event) => {
                setReplyComment(event.target.value);
                setChangesMade(true);
              }}
            />
            <div className="flex justify-content--end">
              <button
                className={"btn btn--register min-width--larger"}
                disabled={isWorking || !changesMade}
                onClick={onReplyToMesage}
              >
                {displayedReply ? "Update Reply" : "Reply"}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      {workingMessage ? (
        <p className="description--small text-align-right padding--small">
          {workingMessage}
        </p>
      ) : null}
    </div>
  );
}

export default ReplyToComment;
