import { useEffect, useState } from "react";
import DefaultProfilePicture from "../../img/profile_picture.png";
import DefaultProfilePictureWhite from "../../img/profile_picture_white.png";
import WBLogo from "../../img/logos/writingBattleLogoSupporting.webp";
import WBLogoWhite from "../../img/logos/writingBattleLogoSupportingWhite.webp";
import {
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import FeedbackRating from "./FeedbackRating";

function DebriefFeedback({ feedbackArray, myProfile, nightMode, debriefMeta }) {
  const [renderedFeedback, setRenderedFeedback] = useState([]);

  useEffect(() => {
    if (feedbackArray?.length) {
      const battleId = debriefMeta?.battleId ? debriefMeta.battleId : "";
      const tempRender = [];
      for (let i = 0; i < feedbackArray.length; i++) {
        const feedback = feedbackArray[i];
        if (!feedback) {
          continue;
        }
        const publicJudgeUserId = feedback?.publicUserId
          ? feedback.publicUserId
          : "";
        let feedbackText = "";
        if (feedback.liked) {
          feedbackText += "What I liked about the story:\n" + feedback.liked;
          feedbackText += "\n\n";
        }
        if (feedback.needsWork) {
          feedbackText +=
            "What I felt might need work with the story:\n" +
            feedback.needsWork;
          feedbackText += "\n\n";
        }

        tempRender.push(
          <div
            style={nightMode ? nightModeStyleLessDark : {}}
            key={i.toString(10) + "_feedback"}
            className="leave-a-comment-section comment-container"
          >
            <div
              className={
                "flex flex--direction-column align-items--center margin-bottom-sm"
              }
            >
              <img
                className="basic-user-info--img"
                src={
                  nightMode ? DefaultProfilePictureWhite : DefaultProfilePicture
                }
              />
            </div>
            <div className="flex flex--direction-column align-items--center">
              <p className="description profile-text">{feedbackText}</p>
              {battleId && publicJudgeUserId && myProfile?.userId ? (
                <FeedbackRating
                  battleId={battleId}
                  publicJudgeUserId={publicJudgeUserId}
                  myUserId={myProfile.userId}
                  feedback={feedback}
                  nightMode={nightMode}
                />
              ) : null}
            </div>
          </div>
        );
      }
      setRenderedFeedback(tempRender);
    }
  }, [feedbackArray, nightMode, debriefMeta, myProfile]);

  const peerJudged = !debriefMeta?.liveTournamentStructure ? true : false;

  return (
    <div style={nightMode ? nightModeStyleWhite : {}} className="container">
      <div className="debrief-feedback-list">
        <div
          style={nightMode ? nightModeStyleLessDark : {}}
          className="comment-container"
        >
          <div className="leave-a-comment-section">
            <div
              className={
                "flex flex--direction-column align-items--center margin-bottom-sm"
              }
            >
              <img
                className="basic-logo--img"
                src={nightMode ? WBLogoWhite : WBLogo}
              />
            </div>
            <p className="description profile-text">
              Hi{" "}
              {myProfile?.publicProfile?.name
                ? myProfile.publicProfile.name
                : "there"}
              ,
              <br />
              <br />
              The following feedback is from your anonymous{" "}
              {peerJudged ? "peers" : "pro judges"} during the Swiss Rounds of
              the tournament. We have intentionally shuffled the order that the
              feedback is presented.
              <br />
              <br />
              Remember that{" "}
              {peerJudged
                ? "these are not trained writing judges or creative writing gurus. " +
                  "They are folks like yourself! People that enjoy writing and were " +
                  "brave enough to enter a contest that puts their creative endeavors" +
                  " online for people to read. For this reason, i"
                : "this is just the opinion of three individual judges. I"}
              t is up to you to decide which piece of feedback to take to heart,
              and which to ignore. Opinions will vary, and not all stories will
              connect with all people.
              <br />
              <br />
              It is easy to get attached to the stories you write, but it's
              important to note that you completed this story in a very short
              amount of time with {"(likely)"} some pretty weird prompts. You
              should feel proud for rising to the challenge and submitting a
              story! Not everyone can do that!
              <br />
              <br />
              We have done our best to weed out anything insulting
              {peerJudged ? " or vulgar" : ""}, but mistakes on our part can
              happen. Please{" "}
              {peerJudged
                ? "let us know if we missed something by emailing us at admin@writingbattle.com"
                : "give a thumbs up or down and provide us with a bit of data on the" +
                  " feedback quality of each Judge so we know which Judges are the right fit for our community."}
              <br />
              <br />
              Congrats on completing a Battle and we hope to see you in the next
              one!
              <br />
              <br />
              Sincerely,
              <br />
              -The Writing Battle Team
            </p>
          </div>
        </div>
        {renderedFeedback}
      </div>
    </div>
  );
}

export default DebriefFeedback;
