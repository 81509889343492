import "../../styles/debrief.css";
import "../../styles/debriefQueries.css";
import { useEffect, useState } from "react";
import { getPublicUserInfo } from "../../publicFunctions/registerHandler";
import BasicUserInfo from "../BasicUserInfo";
import { leaveAComment } from "../../publicFunctions/forumHandler";
import { commentTypeDefault } from "../../publicFunctions/debriefHandler";
import { nightModeStyle } from "../../publicFunctions/publicHelpers/helpers";

function LeaveAComment({
  userInfo,
  storyId,
  forceRefreshComments,
  isFromBattle,
  typeOfComments,
  nightMode,
}) {
  const [myPublicUserInfo, setMyPublicUserInfo] = useState(null);
  const [myComment, setMyComment] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [workingMessage, setWorkingMessage] = useState("");

  useEffect(() => {
    getPublicUserInfo(userInfo.userId).then((result) => {
      setMyPublicUserInfo(result);
    });
  }, [userInfo]);

  const onLeaveAComment = (event) => {
    event.preventDefault();
    setIsWorking(true);
    leaveAComment(storyId, userInfo.userId, myComment)
      .then(() => {
        forceRefreshComments();
        setChangesMade(false);
        setMyComment("");
        setWorkingMessage("Thanks for commenting on this story!");
      })
      .catch((err) => {
        console.log(err);
        setWorkingMessage("Something went wrong. Please try again.");
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  return (
    <div style={nightMode ? nightModeStyle : {}} className="container">
      {!myPublicUserInfo ? (
        <p className="description center-text">Loading...</p>
      ) : null}
      {myPublicUserInfo ? (
        <div
          style={nightMode ? nightModeStyle : {}}
          className={
            "leave-a-comment-section leave-a-comment-selection--shadow leave-a-comment-section--large" +
            (nightMode ? "" : " blue-background ")
          }
        >
          <BasicUserInfo
            initPublicUserInfo={myPublicUserInfo}
            nightMode={nightMode}
          />
          <div>
            {isFromBattle ? (
              <p className="description--small">
                Reminder: if you judged this story as a Spartan, please do not
                mention that in your comment as it could spoil some of the
                results before they are announced.
              </p>
            ) : null}
            <br />
            <p className="description">
              The author has requested the following type of comments:{" "}
              <span className="bold">
                {typeOfComments ? typeOfComments : commentTypeDefault}
              </span>
            </p>
            <label className="description bold" htmlFor="leave-comment">
              Leave a comment
            </label>
            <textarea
              style={nightMode ? nightModeStyle : {}}
              id="leave-comment"
              className="feedback-text-area text-area--resize-vertical"
              value={myComment}
              disabled={isWorking}
              onChange={(event) => {
                setMyComment(event.target.value);
                setChangesMade(true);
              }}
            />
            {workingMessage ? (
              <p className="description text-align-right">{workingMessage}</p>
            ) : null}
            <div className="flex justify-content--end">
              <button
                className={"btn btn--register min-width--larger"}
                disabled={isWorking || !changesMade}
                onClick={onLeaveAComment}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default LeaveAComment;
