import {
  nightModeStyle,
  nightModeStyleLessDark,
} from "../publicFunctions/publicHelpers/helpers";

function BasicNav({
  selectedBattle,
  isLoading,
  battleOptions,
  selectedBattleHandler,
  yearOptions,
  selectedYearHandler,
  selectedYear,
  title,
  hideNav,
  nightMode,
}) {
  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className="container rules-nav"
    >
      <h1 style={nightMode ? nightModeStyle : {}} className="heading-primary">
        {title}
      </h1>
      {!hideNav ? (
        <form className="cta-form rules-form" action="#">
          {yearOptions && selectedYearHandler ? (
            <div className="flex flex--direction-column justify-self--end">
              <label className={nightMode ? "off-white" : "black"}>
                Select Year
              </label>
              <select
                style={nightMode ? nightModeStyleLessDark : {}}
                className="select--small"
                onChange={selectedYearHandler}
                value={selectedYear}
                disabled={isLoading}
                required
              >
                {yearOptions}
              </select>
            </div>
          ) : null}
          <div className="flex flex--direction-column justify-self--end">
            <label className={nightMode ? "off-white" : "black"}>
              Select Battle Title
            </label>
            <select
              style={nightMode ? nightModeStyleLessDark : {}}
              onChange={selectedBattleHandler}
              value={selectedBattle}
              disabled={isLoading}
              required
            >
              {battleOptions}
            </select>
          </div>
        </form>
      ) : null}
    </div>
  );
}

export default BasicNav;
