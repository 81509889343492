import {
  getCardImageUrlByCard,
  getFlagImageUrlByFlagFileName,
} from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import BasicUserInfo from "../BasicUserInfo";
import { useEffect, useState } from "react";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import LastReplyUser from "../LastReplyUser";

function DebriefStory({ story, debriefMeta, i, nightMode }) {
  const [renderedCards, setRenderedCards] = useState([]);
  const [publicUser, setPublicUser] = useState(null);
  const [lastCommentUserId, setLastCommentUserId] = useState("");

  useEffect(() => {
    if (story) {
      // rendered cards
      const tempRenderedCards = [];
      if (story.tinyCardImages?.length) {
        for (let j = 0; j < story.tinyCardImages.length; j++) {
          tempRenderedCards.push(
            <img
              className="debrief-genre-title-card"
              key={i.toString + "_" + j.toString(10) + "_card"}
              src={story.tinyCardImages[j]}
            />
          );
        }
      } else if (story.cards) {
        tempRenderedCards.push(
          <img
            className="debrief-genre-title-card"
            key={"genre_" + story.storyId + "_img"}
            src={getCardImageUrlByCard(story.cards.genre, true)}
          />,
          <img
            className="debrief-genre-title-card"
            key={"prompt1_" + story.storyId + "_img"}
            src={getCardImageUrlByCard(story.cards.prompt1, true)}
          />,
          <img
            className="debrief-genre-title-card"
            key={"prompt2_" + story.storyId + "_img"}
            src={getCardImageUrlByCard(story.cards.prompt2, true)}
          />
        );
      }
      setRenderedCards(tempRenderedCards);
      if (story.lastCommentUserId) {
        setLastCommentUserId(story.lastCommentUserId);
      }
      if (story.userId) {
        getPublicProfileByUserId(story.userId)
          .then((data) => {
            if (data) {
              setPublicUser(data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [story]);

  return (
    <div
      className={
        "debrief-story-container" +
        (nightMode ? " debrief-story-container--nightMode" : "")
      }
      role="button"
    >
      {publicUser ? (
        <div className="flex flex--direction-column align-items--center">
          <BasicUserInfo
            initPublicUserInfo={publicUser}
            nightMode={nightMode}
          />
          {story.houseId && story.flagFileName ? (
            <a
              role="button"
              href={
                "/debrief/?page=houses&houseId=" +
                story.houseId +
                "&battleId=" +
                debriefMeta.battleId
              }
            >
              <img
                className="debrief-flag--small"
                src={getFlagImageUrlByFlagFileName(story.flagFileName)}
              />
            </a>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
      <a
        className="debrief-clickable-container"
        role="button"
        href={
          "/story/" +
          (story.battleId === "tavern" ? "tavern" : "debrief") +
          "/" +
          story.storyId +
          "?uploadedStory"
        }
      >
        <div className="flex flex--direction-column">
          <p className="description--small">{story.genre}</p>
          <div className="flex align-items--center">{renderedCards}</div>
          <p className="description--large bold">{story.storyTitle}</p>
          {publicUser?.name ? (
            <p className="description">{"by " + publicUser.name}</p>
          ) : null}
        </div>
        <div
          className={
            "flex flex--direction-column " +
            "align-items--end break-long-words justify-content--end"
          }
        >
          {lastCommentUserId ? (
            <LastReplyUser
              nightMode={nightMode}
              userId={lastCommentUserId}
              firstText={
                !story?.numberOfComments
                  ? ""
                  : story.numberOfComments.toString(10) +
                    (story.numberOfComments > 1 ? " comments" : " comment")
              }
              secondText={"Last comment by: "}
              date={story?.lastCommentDate ? story.lastCommentDate : null}
            />
          ) : (
            <p className="description--smaller text-align-right">
              Be the first to comment on this story!
            </p>
          )}
        </div>
      </a>
    </div>
  );
}

export default DebriefStory;
