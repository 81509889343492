import "../styles/general.css";
import "../styles/everythingQuill.css";
import "../styles/everythingQuillQueries.css";
import "../styles/battle.css";
import { IonIcon } from "@ionic/react";
import { openOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import StoryHeader from "../components/story/StoryHeader";
import StoryInfo from "../components/story/StoryInfo";
import MyQuillWrapper from "../components/battle/MyQuillWrapper";
import StoryJudging from "../components/story/StoryJudging";
import { requestToReadBattleStory } from "../config/firebase";
import { getUploadedStory } from "../publicFunctions/debriefHandler";
import UploadedStoryCommentSection from "../components/story/UploadedStoryCommentSection";
import {
  modifyStoryLink,
  nightModeStyle,
  nightModeStyleDarkBackgroundOnly,
  nightModeStyleLessDark,
  pageScrollSettings,
} from "../publicFunctions/publicHelpers/helpers";
import SEO from "../components/SEO";
import SharingSettings from "../components/SharingSettings";
import Home from "./Home";
import { getCustomWordCount } from "../publicFunctions/publicHelpers/quillHelpers";
import LoadingSection from "../components/LoadingSection";

function Story({
  userInfo,
  openLogInHandler,
  myProfile,
  battles,
  alreadyJoinedMap,
  nightMode,
  refreshNightMode,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const commentSectionRef = useRef(null);
  const params = useParams();
  const [battleId] = useState(params.battleId);
  const [storyId] = useState(params.storyId);
  const [duelId] = useState(params.duelId);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [storyContent, setStoryContent] = useState(false);
  const [storyTitle, setStoryTitle] = useState("");
  const [contentWarning, setContentWarning] = useState("");
  const [cards, setCards] = useState(null);
  const [message, setMessage] = useState("");
  const [isJudging, setIsJudging] = useState(false);
  const [uploadedStory, setUploadedStory] = useState(null);
  const [docUrl, setDocUrl] = useState("");
  const [genreDefinition, setGenreDefinition] = useState("");
  const [userHasBeenPromptedToLogin, setUserHasBeenPromptedToLogin] =
    useState(false);

  // can be UNKNOWN TRUE FALSE
  const [cwSpoiler, setCWSpoiler] = useState("UNKNOWN");
  const [hideContentWarnings, setHideContentWarnings] = useState("UNKNOWN");

  // NOT_SURE, YES, NO
  const [thisStoryIsPublic, setThisStoryIsPublic] = useState("NOT_SURE");
  // new stuff
  const quillRef = useRef(null);
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const wordCount = getCustomWordCount(editor); // Get the custom word count
      setWordCount(wordCount); // Update word count state
    }
  }, [storyContent, quillRef]);

  const refreshWithStoryDetails = (
    title,
    content,
    argCards,
    argJudging,
    refreshedContentWarning = "",
    argCWSpoiler = false
  ) => {
    const hasDocUrl =
      typeof content === "string" || content instanceof String ? true : false;

    setStoryTitle(title);
    if (hasDocUrl) {
      setDocUrl(content);
    } else {
      setStoryContent(content);
    }
    setCards(argCards);
    setIsJudging(argJudging);
    setContentWarning(refreshedContentWarning);

    if (argCards?.genre?.definition) {
      setGenreDefinition(argCards.genre.definition);
    }
    if (argCWSpoiler) {
      setCWSpoiler("TRUE");
    } else {
      setCWSpoiler("FALSE");
    }
  };

  useEffect(() => {
    if (uploadedStory && searchParams?.has("toComments")) {
      // we only want to do this once and then remove from URL
      const tempSearch = searchParams;
      tempSearch.delete("toComments");
      setSearchParams(tempSearch);

      // Go to the comment section
      if (commentSectionRef?.current) {
        commentSectionRef.current.scrollIntoView(pageScrollSettings);
      }
    }
  }, [uploadedStory]);

  useEffect(() => {
    // Note: when the myprofile object shows the email, then we know settings
    // have been loaded
    if (myProfile?.email) {
      let tempHideContentWarnings = "FALSE";
      if (myProfile.settings?.hideContentWarnings) {
        tempHideContentWarnings = "TRUE";
      }
      setHideContentWarnings(tempHideContentWarnings);
    }
  }, [myProfile]);

  useEffect(() => {
    const thisIsAnUploadedStory =
      searchParams.has("uploadedStory") ||
      battleId === "debrief" ||
      battleId === "uploads" ||
      battleId === "tavern";
    if (thisIsAnUploadedStory && storyId) {
      getUploadedStory(storyId)
        .then((result) => {
          setUploadedStory(result);
          if (result.superPublic) {
            setThisStoryIsPublic("YES");
          } else {
            setThisStoryIsPublic("NO");
          }
          refreshWithStoryDetails(
            result.storyTitle,
            result.docUrl ? result.docUrl : result.storyContent,
            result.cards,
            false,
            "",
            result.cwSpoiler
          );
        })
        .catch(() => {
          // just try again
          getUploadedStory(storyId)
            .then((result) => {
              setUploadedStory(result);
              if (result.superPublic) {
                setThisStoryIsPublic("YES");
              } else {
                setThisStoryIsPublic("NO");
              }
              refreshWithStoryDetails(
                result.storyTitle,
                result.docUrl ? result.docUrl : result.storyContent,
                result.cards,
                false,
                "",
                result.cwSpoiler
              );
            })
            .catch(() => {
              setThisStoryIsPublic("NO");
              setMessage(
                "Hmmm... that didn't seem to work. " +
                  "Do you have permission to read this story? " +
                  "It's possible that this author has delisted their story."
              );
            });
        });
    } else if (battleId && storyId) {
      setThisStoryIsPublic("NO");
      // Attempt to read story
      requestToReadBattleStory({ battleId, storyId, duelId })
        .then((results) => {
          if (results.data.success) {
            refreshWithStoryDetails(
              results.data.story.storyTitle,
              results.data.story.docUrl
                ? results.data.story.docUrl
                : results.data.story.storyContent,
              results.data.story.cards,
              results.data.isJudging,
              results.data.story.contentWarning,
              results.data.story.cwSpoiler
            );
          }
        })
        .catch(() => {
          // try again
          requestToReadBattleStory({ battleId, storyId, duelId })
            .then((results) => {
              if (results.data.success) {
                refreshWithStoryDetails(
                  results.data.story.storyTitle,
                  results.data.story.docUrl
                    ? results.data.story.docUrl
                    : results.data.story.storyContent,
                  results.data.story.cards,
                  results.data.isJudging,
                  results.data.story.contentWarning,
                  results.data.story.cwSpoiler
                );
              }
            })
            .catch((err) => {
              setMessage(
                "Hmmm... that didn't seem to work. " +
                  "Do you have permission to read this story? " +
                  "If this is your own story and you submitted a new draft since " +
                  'this one, close this tab, and click "Preview" again. ' +
                  "Please try reloading the page, or closing the tab and " +
                  "re-opening it. If this problem persists, and you believe it " +
                  "to be a mistake, then please contact admin@writingbattle.com."
              );
            });
        });
    }
  }, [userHasBeenPromptedToLogin]);

  useEffect(() => {
    if (userInfo?.isStillCheckingLogin()) {
      setMessage("One moment, please. Fetching story...");
    } else if (!userInfo?.isStillCheckingLogin() && !userInfo.isLoggedIn()) {
      setIsLoggedIn(false);
      if (hideStoryHeader && thisStoryIsPublic === "NO") {
        setUserHasBeenPromptedToLogin(true);
        openLogInHandler("login");
      }
    } else if (!userInfo?.isStillCheckingLogin() && userInfo.isLoggedIn()) {
      setIsLoggedIn(true);
      setMessage("");
      setUserHasBeenPromptedToLogin(false);
    }
  }, [userInfo, thisStoryIsPublic]);

  const hideStoryHeader =
    battleId === "debrief" || battleId === "uploads" || battleId === "tavern";

  const thisIsMyStory =
    isLoggedIn &&
    userInfo?.userId &&
    uploadedStory?.userId &&
    uploadedStory.userId === userInfo.userId;

  const refreshHandler = () => {
    window.location.reload();
  };

  return (
    <div className={nightMode ? "hero-background--black" : "hero-background"}>
      <SEO
        title={(storyTitle ? storyTitle : "Story") + " | Writing Battle"}
        description=""
        name="Writing Battle"
        type="website"
      />
      {!hideStoryHeader ? (
        <StoryHeader
          userInfo={userInfo}
          openLogInHandler={openLogInHandler}
          navigateTo={hideStoryHeader ? "Debrief" : ""}
          setUserHasBeenPromptedToLogin={setUserHasBeenPromptedToLogin}
          nightMode={nightMode}
          refreshNightMode={refreshNightMode}
        />
      ) : null}
      {((!isLoggedIn && thisStoryIsPublic === "NO") ||
        (!storyContent && !docUrl)) &&
      !userHasBeenPromptedToLogin ? (
        <p
          style={{ maxWidth: "60rem", margin: "0 auto", padding: "1.2rem" }}
          className="description bold center-text"
        >
          {message}
        </p>
      ) : userHasBeenPromptedToLogin ? (
        <p
          style={{ maxWidth: "60rem", margin: "0 auto", padding: "1.2rem" }}
          className="description bold center-text"
        >
          Please log in.
        </p>
      ) : null}
      {uploadedStory?.storyId && thisIsMyStory ? (
        <>
          <br />
          <br />
          <SharingSettings
            type={"Story"}
            isPublic={thisStoryIsPublic === "YES"}
            refreshHandler={refreshHandler}
            documentId={uploadedStory.storyId}
            isDebriefStory={true}
            nightMode={nightMode}
          />
        </>
      ) : null}
      {!storyContent && !docUrl ? (
        <LoadingSection nightMode={nightMode} />
      ) : null}
      {(storyContent || docUrl) && cards ? (
        <div className="battle-section story-section">
          <div className="battle-container white-background">
            <StoryInfo
              cards={cards}
              storyTitle={storyTitle}
              contentWarning={contentWarning}
              hideContentWarnings={hideContentWarnings}
              uploadedStory={uploadedStory}
              isLoggedIn={isLoggedIn || thisStoryIsPublic === "YES"}
              genreDefinition={genreDefinition}
              cwSpoiler={cwSpoiler}
              nightMode={nightMode}
            />
            <div
              style={nightMode ? nightModeStyleDarkBackgroundOnly : {}}
              className="first-stage-container reading-container"
            >
              {storyContent ? (
                <div className="container">
                  <div
                    style={nightMode ? nightModeStyle : {}}
                    className="editor-wrapper margin--none"
                  >
                    <MyQuillWrapper
                      reference={quillRef}
                      storyContent={storyContent}
                      isReadOnly={true}
                      hideHeader={true}
                    />
                    <div>
                      <p className="description">
                        Official Word Count: {wordCount}
                      </p>
                    </div>
                  </div>
                </div>
              ) : docUrl ? (
                <div className="center-text margin-bottom-md">
                  <a
                    target={"_blank"}
                    href={modifyStoryLink(docUrl)}
                    className="btn btn--lightBlue btn--read-story read-content"
                  >
                    <div className="read-content">
                      Read Story
                      <IonIcon icon={openOutline} />
                    </div>
                  </a>
                </div>
              ) : null}
            </div>
            {isJudging && duelId ? (
              <div
                style={nightMode ? nightModeStyleLessDark : {}}
                className="flex justify-content--center"
              >
                <StoryJudging
                  duelId={duelId}
                  storyId={storyId}
                  nightMode={nightMode}
                />
              </div>
            ) : !isJudging && duelId ? (
              <p className="description--small bold center-text">
                Feedback is not required for this Duel.
              </p>
            ) : null}
            {!isJudging &&
            !duelId &&
            uploadedStory?.commentsEnabled &&
            userInfo &&
            isLoggedIn ? (
              <div ref={commentSectionRef}>
                <UploadedStoryCommentSection
                  myProfile={myProfile}
                  uploadedStory={uploadedStory}
                  commentId={
                    searchParams?.has("commentId")
                      ? searchParams.get("commentId")
                      : ""
                  }
                  userInfo={userInfo}
                  isFromBattle={
                    battleId && battleId !== "tavern" ? true : false
                  }
                  nightMode={nightMode}
                />
              </div>
            ) : !isJudging &&
              !duelId &&
              uploadedStory &&
              !uploadedStory.commentsEnabled &&
              userInfo &&
              isLoggedIn ? (
              <p className="description--large bold center-text">
                Comments have been disabled by the author
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
      {!userInfo.isStillCheckingLogin() &&
      !userInfo.isLoggedIn() &&
      thisStoryIsPublic === "YES" &&
      !duelId ? (
        <Home
          battles={battles}
          openLogInHandler={openLogInHandler}
          userInfo={userInfo}
          alreadyJoinedMap={alreadyJoinedMap}
          removeSEO={true}
          nightMode={nightMode}
        />
      ) : null}
    </div>
  );
}

export default Story;
