import { useEffect, useState } from "react";
import { getClientizedDuelInfo, saveFeedback } from "../../config/firebase";
import {
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";

const JUDGING_DUEL_MESSAGE =
  "Be nice with your feedback! Let's all become better writers " +
  "together! If we determine that your feedback is blatantly " +
  "rude or non-constructive, then we may ask you to re-write the " +
  "feedback. In rare circumstances, insulting or vulgar comments " +
  "can lead to disqualification.";
const JUDGING_DUEL_MESSAGE_2 =
  'Do not put any criticisms in the "Liked" ' +
  "section. The first thing people read should be positive. You may have a mix " +
  'of feedback in the "Needs Work" section. That being said, be honest -- ' +
  "just do it in a constructive way.";
const JUDGING_DUEL_MESSAGE_3 =
  "When an admin reads your feedback, it should " +
  "be clear that you read the story. Simple and generic feedback that could apply" +
  " to any and all stories will not be accepted by our admins. No-effort feedback " +
  "can result in disqualification. We have to know that you read both stories.";

function StoryJudging({ duelId, storyId, nightMode }) {
  const [message, setMessage] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [clientizedDuel, setClientizedDuel] = useState(null);
  const [liked, setLiked] = useState("");
  const [needsWork, setNeedsWork] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [loadingDuelMessage, setLoadingDuelMessage] =
    useState("Loading Duel...");
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    if (duelId) {
      getClientizedDuelInfo({ duelId })
        .then((result) => {
          setClientizedDuel(result.data);
          setLoadingDuelMessage("");
        })
        .catch((err) => {
          if (err?.message) {
            setLoadingDuelMessage(err.message);
          } else {
            setLoadingDuelMessage(
              "This Duel failed to load. Please try again."
            );
          }
        });
    }
  }, [duelId]);

  useEffect(() => {
    if (clientizedDuel) {
      // which story? A or B?
      let story;
      if (clientizedDuel.storyA.id === storyId) {
        story = clientizedDuel.storyA;
      } else if (clientizedDuel.storyB.id === storyId) {
        story = clientizedDuel.storyB;
      } else {
        setMessage(
          "There seems to be something wrong with this Duel. " +
            "Please contact admin@writingbattle.com"
        );
      }

      if (story) {
        // let's check if we should display a message
        const tempLiked = story.feedback.liked;
        const tempNeedsWork = story.feedback.needsWork;

        const messageToDisplay = howIsTheFeedbackLooking(
          tempLiked,
          tempNeedsWork,
          story.storyFeedbackReady
        );

        setMessage(messageToDisplay);
        setLiked(tempLiked);
        setNeedsWork(tempNeedsWork);
        // if the duel has started then they no longer have
        // to agree -- they already have
        if (!agreed && (tempLiked || tempNeedsWork)) {
          setAgreed(true);
        }
      }
      // take this info and update the screen
    }
  }, [clientizedDuel]);

  function howIsTheFeedbackLooking(
    argLiked,
    argNeedsWork,
    serverFeedbackReadyObject
  ) {
    if (serverFeedbackReadyObject?.ready) {
      return (
        "Thank you for your feedback! If you feel that you're all done" +
        " adding feedback to this story, close this tab and head back to your " +
        "Duel on the Battle page."
      );
    } else if (!argLiked?.length && !argNeedsWork?.length) {
      return "Please submit feedback for this story.";
    } else if (
      !serverFeedbackReadyObject?.ready &&
      serverFeedbackReadyObject.reason
    ) {
      return (
        "The feedback you left for this story is " +
        "not considered complete for this reason: " +
        serverFeedbackReadyObject.reason
      );
    } else {
      return (
        "The feedback you left for this story is " +
        "not considered complete, and will need additional feedback. " +
        "If you are not done with the feedback, and plan on adding more, " +
        "then ignore this message. "
      );
    }
  }

  const onSaveFeedback = async (event) => {
    event.preventDefault();
    try {
      setIsWorking(true);
      setMessage("Saving...");
      const { data } = await saveFeedback({
        duelId,
        storyId,
        liked,
        needsWork,
      });
      if (data?.updatedDuel) {
        setChangesMade(false);
        setClientizedDuel(data.updatedDuel);
      }
    } catch (err) {
      console.log(err);
      setMessage(err.message);
    } finally {
      setIsWorking(false);
    }
  };

  if (!clientizedDuel) {
    return (
      <div>
        <p className="description center-text bold">{loadingDuelMessage}</p>
      </div>
    );
  }

  const onLikedChange = (event) => {
    setMessage("");
    setChangesMade(true);
    setLiked(event.target.value);
  };

  const onNeedsWorkChange = (event) => {
    setMessage("");
    setChangesMade(true);
    setNeedsWork(event.target.value);
  };

  return (
    <div className="story-judging-section container">
      <div className="judging-message">
        <p
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-tertiary center-text"
        >
          Providing Feedback {"(required)"}
        </p>
        <em>
          <p className="description center-text max-width--readable-center">
            Please note: Auto-generated (AI) feedback is prohibited and will
            result in immediate disqualification and a permanent ban from
            Writing Battle.
          </p>
        </em>
        <br />
        <p className="description--small">{JUDGING_DUEL_MESSAGE}</p>
        <br />
        <p className="description--small">{JUDGING_DUEL_MESSAGE_2}</p>
        <br />
        <p className="description--small">{JUDGING_DUEL_MESSAGE_3}</p>
        <br />
        <br />
      </div>
      <div
        style={nightMode ? nightModeStyle : {}}
        className="checkbox--wrapper border-thin max-width--readable-center"
      >
        <input
          required
          id="agreedToRules"
          className="checkbox"
          type="checkbox"
          value="agreed"
          checked={agreed}
          disabled={isWorking}
          onChange={() => {
            if (!agreed) {
              setAgreed(true);
            }
          }}
        />
        <label
          htmlFor="agreedToRules"
          className={
            "description--small " + (nightMode ? "off-white" : "red--darker")
          }
        >
          (*REQUIRED) I agree that this competition allows for a diverse
          interpretation of Genre. If a story can be reasonably interpreted to
          fall under the assigned Genre, I will presume the story complies with
          the Genre. I do <strong>NOT</strong> have the power to disqualify a
          story for any reason. If I believe a story should be disqualified,
          then I must report this to an admin of Writing Battle.
        </label>
      </div>
      {agreed ? (
        <>
          <label className="description bold" htmlFor="battle-feedback-liked">
            Liked
          </label>
          <textarea
            style={nightMode ? nightModeStyle : {}}
            id="battle-feedback-liked"
            className="feedback-text-area"
            value={liked}
            onChange={onLikedChange}
          />
          <label
            className="description bold"
            htmlFor="battle-feedback-needsWork"
          >
            What I feel could use some work {"(in my opinion)"}
          </label>
          <textarea
            style={nightMode ? nightModeStyle : {}}
            id="battle-feedback-needsWork"
            className="feedback-text-area"
            value={needsWork}
            onChange={onNeedsWorkChange}
          />
          <p className="description center-text max-width--readable-center">
            {message}
          </p>
          <div className="margin-auto">
            <button
              className="btn btn--register min-width--larger max-width--larger story-submission-btn"
              disabled={isWorking || !changesMade}
              onClick={onSaveFeedback}
            >
              Save Feedback
              <div className={isWorking ? "simple-loader" : "hidden"}>
                <Lottie
                  loop
                  animationData={loadingAnimation}
                  speed={1.5}
                  play
                ></Lottie>
              </div>
            </button>
          </div>
        </>
      ) : (
        <p className="description bold center-text max-width--readable-center">
          To leave feedback, you must agree to the above statement by checking
          the box.
        </p>
      )}
    </div>
  );
}

export default StoryJudging;
