import TestimonialImage1 from "../../img/participants/testimonial1.webp";
import TestimonialImage2 from "../../img/participants/testimonial2.webp";
import TestimonialImage3 from "../../img/participants/testimonial3.webp";
import TestimonialImage5 from "../../img/participants/testimonial5.webp";
import TestimonialImage6 from "../../img/participants/testimonial6.webp";
import TestimonialImage7 from "../../img/participants/testimonial7.webp";
import TestimonialImage8 from "../../img/participants/testimonial8.webp";
import Participant1Image from "../../img/participants/participant4_128.jpg";
import Reveal from "../animations/Reveal";
import Testimonial from "./Testimonial";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function FinalTestimonialSection({ nightMode }) {
  return (
    <div
      style={nightMode ? nightModeStyleWhite : {}}
      className="container padding-top-bot--md"
    >
      <div className="how-container">
        <Reveal type="fromBottom" rotate={true}>
          <span className={"subheading" + (nightMode ? " blue" : "")}>
            Testimonials (cont'd)
          </span>
        </Reveal>
        <Reveal type="fromBottom" rotate={true}>
          <h2
            style={nightMode ? nightModeStyleWhite : {}}
            className="heading-secondary"
          >
            Over 4 years of Battles
          </h2>
        </Reveal>
      </div>
      <div className="final-testimonial-grid">
        <Testimonial
          participantImage={TestimonialImage1}
          participantName="Lisa Short"
          quote="One of my favourite things is that I can trade the genre one time!"
        />
        <Testimonial
          participantImage={TestimonialImage2}
          participantName="Melanie Mulrooney"
          quote="Folks here genuinely care about helping each other improve as writers. I especially love seeing this in action during debrief."
        />
        <Testimonial
          participantImage={TestimonialImage3}
          participantName="Lousie"
          quote="Fast turnaround. I haven't forgotten about my story by the time results come round."
        />
        <Testimonial
          participantImage={TestimonialImage8}
          participantName="Suzanne Link"
          quote="The genres. I love the variety, from traditional to WTF! (I'm looking at you, Cannibal Comedy. It didn't win, but it was one of the most enjoyable pieces to write across all contests.)"
        />
        <Testimonial
          participantImage={Participant1Image}
          participantName="EJ Paras"
          quote="In the Peer-judged Battles, judging was fun; I really had to assess and weigh the pros and cons."
        />
        <Testimonial
          participantImage={TestimonialImage5}
          participantName="Sarah Hurd"
          quote="I enjoy the diverse experience levels that make up this community, including brand new writers, seasoned/published writers, and all in-between."
        />
      </div>
    </div>
  );
}

export default FinalTestimonialSection;
